import { Row } from "reactstrap"

export const BasicPagination = (
  data: any,
  getCanPreviousPage: any,
  previousPage: any,
  getPageOptions: any,
  getState: any,
  setPageIndex: any,
  getCanNextPage: any,
  nextPage: any
) => {
  const { pageIndex } = getState().pagination
  const pageOptions = getPageOptions()
  const totalPages = pageOptions.length

  const renderPageNumbers = () => {
    const pages = []

    // Always show the first page
    pages.push(
      <li className="page-item" key={0}>
        <button
          className={pageIndex === 0 ? "page-link active" : "page-link"}
          onClick={() => setPageIndex(0)}
        >
          1
        </button>
      </li>
    )
    // Show ellipsis if the current page is not adjacent to the first page
    if (pageIndex > 1) {
      pages.push(
        <li className="page-item disabled" key="start-ellipsis">
          <span className="page-link">...</span>
        </li>
      )
    }
    // Show the current page if it's not the first or last page
    if (pageIndex > 0 && pageIndex < totalPages - 1) {
      pages.push(
        <li className="page-item" key={pageIndex}>
          <button
            className={
              pageIndex === pageIndex ? "page-link active" : "page-link"
            }
            onClick={() => setPageIndex(pageIndex)}
          >
            {pageIndex + 1}
          </button>
        </li>
      )
    }
    // Show ellipsis if the current page is not adjacent to the last page
    if (pageIndex < totalPages - 2) {
      pages.push(
        <li className="page-item disabled" key="end-ellipsis">
          <span className="page-link">...</span>
        </li>
      )
    }
    // Always show the last page
    pages.push(
      <li className="page-item" key={totalPages - 1}>
        <button
          className={
            pageIndex === totalPages - 1 ? "page-link active" : "page-link"
          }
          onClick={() => setPageIndex(totalPages - 1)}
        >
          {totalPages}
        </button>
      </li>
    )
    return pages
  }

  return (
    <Row className="align-items-center mt-2 mb-2 px-2 g-3 text-center text-sm-start">
      <div className="col-sm">
        <div className="text-muted">
          Total Of<span className="fw-semibold ms-1">{data?.length}</span>{" "}
          Results
        </div>
      </div>
      <div className="col-sm-auto">
        <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0 flex-wrap">
          {/* First Page Button */}
          <li
            className={
              pageIndex === 0 ? "page-item disabled" : "page-item"
            }
          >
            <button
              className="page-link"
              onClick={() => setPageIndex(0)}
              disabled={pageIndex === 0}
            >
              {"<<"}
            </button>
          </li>

          {/* Previous Page Button */}
          <li
            className={
              !getCanPreviousPage() ? "page-item disabled" : "page-item"
            }
          >
            <button className="page-link" onClick={previousPage}>
              <i className="ri-arrow-left-s-line" />
            </button>
          </li>

          {/* Page Numbers */}
          {renderPageNumbers()}

          {/* Next Page Button */}
          <li
            className={!getCanNextPage() ? "page-item disabled" : "page-item"}
          >
            <button className="page-link" onClick={nextPage}>
              <i className="ri-arrow-right-s-line" />
            </button>
          </li>

          {/* Last Page Button */}
          <li
            className={
              pageIndex === totalPages - 1 ? "page-item disabled" : "page-item"
            }
          >
            <button
              className="page-link"
              onClick={() => setPageIndex(totalPages - 1)}
              disabled={pageIndex === totalPages - 1}
            >
              {">>"}
            </button>
          </li>
        </ul>
      </div>
    </Row>
  )
}

export const PaginatedIndex = (currentPageNumber: any,pageLimit: any, setCurrentPageNumber:any ) => {
  const isMobile = window.innerWidth < 768
  return (
    <Row className="align-items-center mt-2 mb-2 px-2 g-3 text-center text-sm-start">
      <div className="col-sm">
        <div className="text-muted">
          Showing{" "}
          <span className="fw-semibold ms-1">
            {(currentPageNumber - 1) * pageLimit?.page_size + 1 || 0} -{" "}
            {Math.min(
              currentPageNumber * pageLimit?.page_size,
              pageLimit?.total_items
            ) || 0}
          </span>{" "}
          of {pageLimit?.total_items || 0} Total Results
        </div>
      </div>
      <div className="col-sm-auto">
        <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0 flex-wrap">
          {/* First Page Button */}
          <li
            className={
              currentPageNumber <= 1 ? "page-item disabled" : "page-item"
            }
          >
            <button
              className="page-link"
              onClick={() => setCurrentPageNumber(1)}
              disabled={currentPageNumber <= 1}
            >
              {"<<"}
            </button>
          </li>

          {/* Previous Page Button */}
          <li
            className={
              currentPageNumber <= 1 ? "page-item disabled" : "page-item"
            }
          >
            <button
              className="page-link"
              onClick={() => setCurrentPageNumber(currentPageNumber - 1)}
              disabled={currentPageNumber <= 1}
            >
              <i className="ri-arrow-left-s-line" />
            </button>
          </li>

          {/* Page Numbers */}
          {(() => {
            const pages = []
            const totalPages = pageLimit?.total_pages || 1

            if (isMobile) {
              // Mobile view: Show 1 ... last
              pages.push(1)
              if (currentPageNumber > 2) {
                pages.push("...")
              }
              if (currentPageNumber > 1 && currentPageNumber < totalPages) {
                pages.push(currentPageNumber)
              }
              if (currentPageNumber < totalPages - 1) {
                pages.push("...")
              }
              pages.push(totalPages)
            } else {
              // Desktop view: Show more pages
            if (currentPageNumber > 3) {
              pages.push(1, 2, 3)
              pages.push("...")
              const startPage = Math.max(currentPageNumber - 2, 4)
              const endPage = Math.min(currentPageNumber + 2, totalPages)
              for (let i = startPage; i <= endPage; i++) {
                pages.push(i)
              }
            } else {
              for (let i = 1; i <= currentPageNumber; i++) {
                pages.push(i)
                }
              }
            }

            return pages?.map((page, index) => (
              <li className="page-item" key={index}>
                {page === "..." ? (
                  <button className="page-link disabled">...</button>
                ) : (
                  <button
                    className={
                      currentPageNumber === page
                        ? "page-link active"
                        : "page-link"
                    }
                    onClick={() => setCurrentPageNumber(page)}
                  >
                    {page}
                  </button>
                )}
              </li>
            ))
          })()}

          {/* Next Page Button */}
          <li
            className={
              currentPageNumber === pageLimit?.total_pages
                ? "page-item disabled"
                : "page-item"
            }
          >
            <button
              className="page-link"
              onClick={() => setCurrentPageNumber(currentPageNumber + 1)}
              disabled={
                currentPageNumber === pageLimit?.total_pages ||
                pageLimit?.total_pages === 0
              }
            >
              <i className="ri-arrow-right-s-line" />
            </button>
          </li>

          {/* Last Page Button */}
          <li
            className={
              currentPageNumber === pageLimit?.total_pages
                ? "page-item disabled"
                : "page-item"
            }
          >
            <button
              className="page-link"
              onClick={() => setCurrentPageNumber(pageLimit?.total_pages)}
              disabled={
                currentPageNumber === pageLimit?.total_pages ||
                pageLimit?.total_pages === 0
              }
            >
              {">>"}
            </button>
          </li>
        </ul>
      </div>
    </Row>
  )
}
