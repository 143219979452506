import { GET_TICKET_LIST } from "Components/constants/constants";
import { determineBaseUrlAndPerformApiCall } from "slices/interceptor/interceptor";
import { fetchTicketsSuccess, fetchTicketsFailure, fetchTicketsStart } from "./reducer";

export const fetchTickets = () => async (dispatch) => {
    dispatch(fetchTicketsStart())

    try {
        const response = await determineBaseUrlAndPerformApiCall(`${GET_TICKET_LIST}`,'GET')
        if (response.error && response.error.code === 'NotFound') {
            dispatch(fetchTicketsSuccess([]));
        }
        else {
            dispatch(fetchTicketsSuccess(response));
        }
    }
    catch (error) {
        dispatch(fetchTicketsFailure(error.message))
    }
}