import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
//import logo
import logo from "../assets/images/tfs-icon.png"
import { determineBaseUrlAndPerformApiCall } from "slices/interceptor/interceptor"
import { HEALTH_CHECK } from "Components/constants/constants"
//Import Components
import VerticalLayout from "./VerticalLayouts"
import { Container } from "reactstrap"
import { DateTime } from "ts-luxon"
import { formatDate, formatTime } from "helpers/Formatters/DateFormatter"

interface HealthCheckData {
  status: any
  timestamp: any
}

const Sidebar = ({ layoutType }: any) => {
  const [healthcheckData, setHealthcheckData] = useState<HealthCheckData | any>(
    null
  )
  useEffect(() => {
    const verticalOverlay = document.getElementsByClassName("vertical-overlay")
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener("click", function() {
        document.body.classList.remove("vertical-sidebar-enable")
      })
    }
  }, [])

  useEffect(() => {
    const fetchHealthCheck = async () => {
      try {
        const response = await determineBaseUrlAndPerformApiCall(
          HEALTH_CHECK,
          "GET"
        )
        if (response) {
          setHealthcheckData(response)
        }
      } catch (e) {
        console.error("API Error:", e)
      }
    }

    fetchHealthCheck() // Fetch health check on mount
  }, [])

  // Update time every second (1000 ms)
  useEffect(() => {
    const intervalId = setInterval(() => {
      // Update current time every second
      setCurrentTime(DateTime.local().setZone("America/New_York"))
    }, 1000) // 1000 ms = 1 second

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(intervalId)
  }, [])

  const [currentTime, setCurrentTime] = useState(
    DateTime.local().setZone("America/New_York")
  )

  // Ensure the time is always in EDT
  const timeInEDT = currentTime.setZone("America/New_York", {
    keepLocalTime: true,
  })

  // Format the date, time, and day
  const formattedDate = timeInEDT.toFormat("yyyy-MM-dd")
  const formattedTime = timeInEDT.toFormat("hh:mm:ss a")
  const formattedDay = timeInEDT.toFormat("cccc")

  const statusColor =
    healthcheckData?.status === "success" ? "bg-green-400" : "bg-red-500"

  return (
    <React.Fragment>
      <div className="app-menu navbar-menu">
        <div className="h-12 mx-4 my-3 flex justify-center">
          <Link to="/">
            <img src={logo} className="h-12 md:h-6 lg:h-12" alt="tfs-logo" />
          </Link>
        </div>
        <React.Fragment>
          <div
            id="scrollbar"
            className="flex h-5/6 sm:h-5/6 md:h-full lg:h-full flex-col justify-between"
          >
            <Container fluid>
              <div id="two-column-menu"></div>
              <ul className="navbar-nav" id="navbar-nav">
                <VerticalLayout layoutType={layoutType} />
              </ul>
            </Container>
            <Container fluid>
              <div className="navbar-nav">
                <div className="nav-link menu-link">
                  <i className="rmx h-5 text-white bg-red text-xl ri-calendar-2-line"></i>
                  <span className="text-white pt-1.5">{formattedDay}</span>
                </div>
                <div className="nav-link menu-link">
                  <i className="rmx h-5 text-white bg-red text-xl ri-calendar-event-line"></i>
                  <span className="flex gap-0 text-white flex-col">
                    <span className="leading-none mb-1">{formattedDate},</span>
                    <span>{formattedTime}</span>
                  </span>
                </div>
                <hr className="mx-3 my-1 text-white" />
                <div className="nav-link menu-link">
                  <span className="flex items-center space-x-2">
                    <span className="text-white">Health Check Status:</span>
                    <span
                      className={`w-3 h-3 rounded-full ${statusColor}`}
                    ></span>
                  </span>
                </div>
                <div className="nav-link menu-link">
                  <span className="flex gap-0 text-white flex-col">
                    <span className="leading-none mb-1">
                      Last Health Check Run:
                    </span>
                    <span>{formatDate(healthcheckData?.timestamp, "YYYY-MM-DD")}, {formatTime(healthcheckData?.timestamp)}</span>
                  </span>
                </div>
              </div>
            </Container>
          </div>
          <div className="sidebar-background"></div>
        </React.Fragment>
      </div>
      <div className="vertical-overlay"></div>
    </React.Fragment>
  )
}

export default Sidebar
