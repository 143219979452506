import { createSlice } from "@reduxjs/toolkit"

interface LoginProps {
  isUserLoggedIn: boolean
  user: any
  error: string
  loading: boolean
  errorMsg: boolean
}

export const initialState: LoginProps = {
  user: {},
  error: "", // for error message
  loading: true,
  isUserLoggedIn: false,
  errorMsg: false, // for error
}

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    apiError(state: any, action: any) {
      state.error = action.payload.data
      state.loading = true
      state.isUserLoggedIn = true
      state.errorMsg = true
    },
    loginSuccess(state) {
      state.loading = false
      state.errorMsg = false
      state.isUserLoggedIn = true
    },
    logoutUserSuccess(state) {
      state.isUserLoggedIn = false
    },
  },
})

export const { apiError, loginSuccess, logoutUserSuccess } = loginSlice.actions

export default loginSlice.reducer
