import React, { useEffect, useState } from "react"
import { getSessionStorageData } from "helpers/Formatters/Format"
import {
  calculateRemainingTime,
  formatTime,
} from "helpers/Formatters/DateFormatter"
import loadingIcon from "../../../../assets/images/loading.png"
import { renderStatusBadge } from "Components/Common/StatusBadge"
import { buttonConfig, taskIconConfig } from "../Task/constant"
import {
  STATUS_FAILED,
  STATUS_READY,
  STATUS_COMPLETED,
  STATUS_IN_PROGRESS,
} from "Components/constants/constants"

export const TaskData = ({ taskData, setOpenTab }: any) => {
  const [remainingTime, setRemainingTime] = useState<string>("")

  // Get the disabled, failed, and escalate states for the Task
  const isDisabled = getSessionStorageData(`started_${taskData?.id}`)
  const isFailed = getSessionStorageData(`restarted_${taskData?.id}`)
  const isRestarted = getSessionStorageData(`escalated_${taskData?.id}`)

  // Updates remaining time every second
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (taskData?.start_time !== "" && taskData?.allocated_time !== 0) {
        const newRemainingTime = calculateRemainingTime(
          taskData?.start_time,
          taskData?.allocated_time
        )
        setRemainingTime(newRemainingTime)
      }
    }, 1000) // Update every second
    return () => clearInterval(intervalId)
  }, [taskData])

  // Helper to render task icons
  const renderTaskIcon = (taskName: string, status: string) => {
    const taskIcons =
      taskIconConfig[taskName as keyof typeof taskIconConfig] || {}
    const statusIcon = taskIcons[status as keyof typeof taskIcons] ||
      taskIcons["icon"] || { icon: "ri-question-line" }
    return (
      <div
        className={`avatar-title rounded-circle fs-14 ${
          status === STATUS_COMPLETED ? "bg-[#0AB39C]" : "bg-white"
        }`}
      >
        <i
          className={`${
            status === STATUS_READY || STATUS_IN_PROGRESS ? statusIcon.icon : ""
          } ${status === STATUS_COMPLETED ? "text-white" : "text-[#0AB39C]"}`}
        />
      </div>
    )
  }

  // Helper to render action buttons
  const renderActionButton = () => {
    const { key_name, status, id, current_action, escalation_path } =
      taskData || {}

    const key = `${key_name}:${status}`
    const config = buttonConfig[key as keyof typeof buttonConfig]

    if (config && (config.condition === undefined || config.condition)) {
      return (
        <button
          className={`shadow-inner-all-sides text-sm font-bold py-1 px-3 rounded-md border-[0.09px] border-[#51718b] 
                        ${
                          isFailed && isDisabled && isRestarted
                            ? "bg-[#355c72] text-gray-300"
                            : "animate-pulse text-white bg-[#31506A] active:bg-[#5483a9]"
                        }
                        `}
          onClick={() =>
            setOpenTab({
              taskId: id,
              taskName: config.label,
              actionName: config.actionName,
              actionPath: current_action?.action_path,
              esclationUrl: escalation_path,
              actionKeyName: config.actionKeyName,
              open: true,
              label: config.label,
            })
          }
          hidden={
            (status === STATUS_FAILED && isFailed) ||
            (status === STATUS_READY && isDisabled) ||
            isRestarted
          }
          disabled={
            (status === STATUS_FAILED && isFailed) ||
            (status === STATUS_READY && isDisabled) ||
            isRestarted
          }
        >
          {config.label}
        </button>
      )
    }
    return null
  }

  return (
    <>
      <tr>
        <td className="p-0 h-8">
          <div className="h-20">
            <div className="inset-0 ml-3 h-16 rounded-l-lg bg-light border-b-8 border-b-[#DAF4F0]">
              <div className="flex items-center justify-center h-full">
                <div className="flex items-center justify-start gap-2 col-span-2 w-full pl-3 py-3">
                  <div className="flex-shrink-0 avatar-xs align-self-start">
                    {renderTaskIcon(taskData?.key_name, taskData?.status)}
                  </div>
                  <h6 className="font-semibold p-0 m-0">
                    {taskData?.display_name
                      ?.toLowerCase()
                      .replace(/\b\w/g, (char: string) => char.toUpperCase())}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td className="p-0 m-0 h-8">
          <div className="h-20">
            <div className="h-16 bg-light border-b-8 border-b-[#DAF4F0] flex items-center">
              <h6 className="px-4 m-0 ">{taskData?.id}</h6>
            </div>
          </div>
        </td>
        <td className="p-0 m-0 h-8">
          <div className="h-20">
            <div className="border-b-8 h-16 border-b-[#DAF4F0] bg-light flex items-center">
              <h6 className="px-4 m-0">
                {taskData?.start_time ? formatTime(taskData?.start_time) : "-"}
              </h6>
            </div>
          </div>
        </td>
        <td className="p-0 m-0 h-8">
          <div className="h-20">
            <div className="border-b-8 h-16 border-b-[#DAf4F0] bg-light py-4 flex items-center">
              <h6 className="px-4 m-0">
                {taskData?.end_time ? formatTime(taskData?.end_time) : "-"}
              </h6>
            </div>
          </div>
        </td>
        <td className="p-0 m-0 h-8">
          <div className="h-20">
            <div className="border-b-8 h-16 border-b-[#DAf4F0] bg-light py-4 flex items-center">
              <h6 className="px-4 m-0">
                {taskData?.start_time
                  ? taskData?.status.toLowerCase() !== "completed"
                    ? remainingTime
                    : "-"
                  : "-"}
              </h6>
            </div>
          </div>
        </td>
        <td className="p-0 m-0 h-8">
          <div className="h-20">
            <div className="border-b-8 h-16 border-b-[#DAF4F0] bg-light py-4 flex items-center">
              <h6 className="px-4 m-0">
                {taskData?.status.toLowerCase() === "in_progress" ||
                taskData?.isLoading ||
                (isFailed && taskData?.status.toLowerCase() === "failed") ||
                ((isDisabled || isRestarted) &&
                  taskData?.status.toLowerCase() === "ready") ? (
                  taskData?.key_name === "start_outbound" ? (
                    renderStatusBadge("in_progress")
                  ) : (
                    <div className="w-full h-full">
                      <img
                        src={loadingIcon}
                        className=" animate-spin h-6"
                        alt="loading-icon"
                      />
                    </div>
                  )
                ) : (
                  renderStatusBadge(taskData?.status)
                )}
              </h6>
            </div>
          </div>
        </td>
        <td className="p-0 m-0 h-8">
          <div className="h-20">
            <div className="mr-3 border-b-8 h-16 border-b-[#DAF4F0] bg-light py-4 rounded-r-lg flex items-center">
              {renderActionButton()}
            </div>
          </div>
        </td>
      </tr>
    </>
  )
}
