import React, { useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import {
  STATUS_COMPLETED,
  TASK_CREATE_BUNDLE,
} from "Components/constants/constants"
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap"
import { ToastContainer } from "react-toastify"
import BreadCrumb from "../../../Components/Common/BreadCrumb"
import { TicketListing } from "pages/Tickets/Listing"
import { determineBaseUrlAndPerformApiCall } from "slices/interceptor/interceptor"
import { TaskCardContent } from "./TaskCard"
import { Slider } from "Components/Common/Slider/SliderLayout"
import {
  renderTaskStatistics,
  TaskActionContent,
} from "Components/Common/Slider/Content/TaskActionContent"
import { TaskEscalationContent } from "Components/Common/Slider/Content/TaskEscalationContent"
import {
  handleStartButtonClick,
  handleTicketEsclations,
} from "helpers/Workflow/helper"
import {
  Workflow,
  Tasks,
  Statistics,
  UpdateTask,
  Tickets,
} from "../../../Components/Workflow/PaymentProcessing"
import {
  handleTaskRestartButtonClick as HandleTaskRestart,
} from "../../../Components/Workflow/constants/constantFunctions"

const PaymentProcessing = ({ data: propsData }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const state = location?.state || propsData

  const [stats, setStats] = useState([])
  const [tasks, setTasks] = useState([])
  const [isOutbound, setIsOutbound] = useState(false)
  const [workflowStatus, setWorkflowStatus] = useState("")
  const [ticketsData, setTicketsData] = useState([])
  const [openTab, setOpenTab] = useState({ open: false })
  const [openEscalation, setOpenEscalation] = useState({
    open: false,
    disabled: false,
  })
  const [startTime, setStartTime] = useState("")
  const [taskStatistics, setTaskStatistics] = useState([])

  const ticketTitle = useRef(null)
  const ticketSubject = useRef(null)

  const { tickets, loadingTickets } = useSelector((state) => state.Ticket)
  const { workflows, loading } = useSelector((state) => state.Workflow)
  const { statistics, statisticsLoading } = useSelector(
    (state) => state.Statistics
  )
  document.title = `${state?.key?.name} | TFS Processing App`

  Workflow(state, setStartTime, setWorkflowStatus, dispatch)
  Tasks(
    workflows,
    loading,
    state,
    tasks,
    setTasks,
    setStartTime,
    setWorkflowStatus,
    setIsOutbound,
    setTaskStatistics,
    setStats,
    dispatch,
    isOutbound
  )
  Statistics(statistics, statisticsLoading, isOutbound, setStats)
  UpdateTask(state, tasks, isOutbound, setTasks)
  Tickets(tickets, loadingTickets, state, setTicketsData)

  const handleTaskRestartButtonClick = async (taskId, taskName, actionPath) => {
    HandleTaskRestart(
      taskId,
      taskName,
      actionPath,
      setOpenTab,
      determineBaseUrlAndPerformApiCall
    )
  }

  const handleEscalate = () => {
    setOpenTab({ open: false })
    setOpenEscalation({
      open: true,
      id: openTab.taskId,
      taskKeyName: openTab?.actionKeyName,
      escalationUrl: openTab?.escalationUrl,
      actionName: openTab?.actionName,
    })
  }

  const handleConfirm = () => {
    if (openTab.label === "Restart") {
      handleTaskRestartButtonClick(
        openTab.taskId,
        openTab.actionKeyName,
        openTab.actionPath
      )
    } else {
      handleStartButtonClick({
        taskId: openTab.taskId,
        actionPath: openTab.actionPath,
        actionKeyName: openTab.actionKeyName,
        setOpenTab: setOpenTab,
      })
    }
  }

  const handleTicketConfirmation = () => {
    handleTicketEsclations({
      taskId: openEscalation?.id,
      actionKeyName: openEscalation?.taskKeyName,
      setOpenTab: setOpenTab,
      setOpenEscalation: setOpenEscalation,
      ticketTitle: ticketTitle,
      ticketSubject: ticketSubject,
      workflowId: state?.key?.workflow_id,
      workflows: workflows,
    })
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content" id="processingContent">
        <Slider
          isOpen={openEscalation.open}
          setOpen={setOpenEscalation}
          actionName={openEscalation.actionName}
        >
          <TaskEscalationContent
            ticketTitle={ticketTitle}
            ticketSubject={ticketSubject}
            setOpenEscalation={setOpenEscalation}
            handleTicketConfirmation={handleTicketConfirmation}
          />
        </Slider>

        {/* Slider for Manual Action */}
        <Slider
          isOpen={openTab.open}
          setOpen={setOpenTab}
          actionName={openTab.actionName}
        >
          <TaskActionContent
            openTab={openTab}
            setOpenTab={setOpenTab}
            taskStatistics={taskStatistics}
            stats={stats}
            handleEscalate={handleEscalate}
            handleConfirm={handleConfirm}
            tasks={tasks}
            workflowId={state?.key?.workflow_id}
            ticketsData={ticketsData}
            isOutbound={isOutbound}
          />
        </Slider>

        <Container fluid>
          {/* Page Heading */}
          <BreadCrumb
            title={state?.key?.name}
            pageTitle="Workflow"
            action="/workflow"
            startTime={startTime}
          />
          <Row>
            <Col lg={12}>
              <Card className="border-none">
                <TaskCardContent
                  startTime={startTime}
                  allocatedTime={state?.key?.allocatedTime}
                  status={workflowStatus}
                  tasks={tasks}
                  openTab={openTab}
                  setOpenTab={setOpenTab}
                />
              </Card>
            </Col>
          </Row>

          {/* Workflow Statistics */}
          {tasks?.some(
            (task) =>
              task.key_name === TASK_CREATE_BUNDLE &&
              task.status === STATUS_COMPLETED
          ) && renderTaskStatistics(stats, isOutbound)}

          {/* Workflow Tickets */}
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">TICKETS</h5>
                </CardHeader>
                <CardBody>
                  <TicketListing tickets={ticketsData} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PaymentProcessing
