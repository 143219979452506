import {
  TASK_CREATE_BUNDLE,
  STATUS_COMPLETED,
  OUTBOUND_ACH_WORKFLOW
} from "../../constants/constants"
import { getStatisticsByWorkflowId } from "slices/workflow/statistics/thunk"
import { toast } from "react-toastify"

export const getWorkflowById = (workflows, targetId) => {
  return workflows?.find((workflow) => workflow.id === targetId)
}

export const getTasksByWorkflowId = (workflows, targetId) => {
  const workflow = getWorkflowById(workflows, targetId)
  return workflow ? workflow?.tasks : []
}

export const hasCompletedBundle = (
  matchingWorkflow,
  targetId,
  dispatch,
  setStats
) => {
  const hasCompletedBundle = matchingWorkflow?.tasks?.some(
    (obj) =>
      obj.key_name === TASK_CREATE_BUNDLE && obj.status === STATUS_COMPLETED
  )

  if (hasCompletedBundle) {
    if (matchingWorkflow?.workflow_key_name === OUTBOUND_ACH_WORKFLOW) {
      dispatch(getStatisticsByWorkflowId(targetId, matchingWorkflow))
    } else {
      const create_bundle_task = matchingWorkflow?.tasks?.filter(
        (item) =>
          item.key_name === TASK_CREATE_BUNDLE &&
          item.status === STATUS_COMPLETED
      )
      setStats([create_bundle_task[0]?.result])
    }
  }
}

export const handleTaskRestartButtonClick = async (
  taskId,
  taskName,
  actionPath,
  setOpenTab,
  determineBaseUrlAndPerformApiCall
) => {
  setOpenTab({ open: false })

  const workflow = actionPath.replace("/inbound/", "")
  sessionStorage.setItem(`restarted_${taskId}`, JSON.stringify(true))

  const pathUri = `restart_${workflow}_task`
  const body = JSON.stringify({
    [`${taskName}_task_id`]: taskId,
  })

  try {
    const response = await determineBaseUrlAndPerformApiCall(
      `/inbound/${pathUri}`,
      "POST",
      body
    )

    if (!response.error) {
      toast.success("Task Restarted Successfully!", {
        position: "top-center",
      })
    } else {
      toast.error(response.error.message, {
        position: "top-center",
      })
    }
  } catch (errorResp) {
    toast.error(errorResp, {
      position: "top-center",
    })
  }
}