import React, { useMemo } from "react"
import TableContainer from "../../Components/Common/Table/TableContainer"
import { useNavigate } from "react-router-dom"
import loadingIcon from "../../assets/images/loading.png"
import { getStatusBadge } from "helpers/Formatters/Format"
import {
  STATUS_READY,
  TASK_APPROVE_CREATE_BUNDLE,
  TASK_UPLOAD_BUNDLE,
  APPROVE,
  START,
  OUTBOUND_TASKS,
} from "Components/constants/constants"

export const ActiveActionList = ({ setOpenTab, actionsData }) => {
  const navigate = useNavigate()

  // Filter out OUTBOUND_TASKS before rendering
  const filteredData = actionsData?.filter(
    (action) => !OUTBOUND_TASKS.includes(action.taskName)
  )

  const columns = useMemo(
    () => [
      {
        header: "Action",
        accessorKey: "actionName",
        enableColumnFilter: false,
      },
      {
        header: "Task Name",
        accessorKey: "taskName",
        enableColumnFilter: false,
      },
      {
        header: "Workflow",
        accessorKey: "workflowName",
        enableColumnFilter: false,
        cell: (cell) => {
          const row = cell.row.original
          const key = row?.key
          return (
            <span
              className="text-blue-500 hover:underline cursor-pointer"
              onClick={() => {
                if (key) {
                  navigate("/payment-processing", {
                    state: { key: key },
                  })
                }
              }}
            >
              {cell.getValue()}
            </span>
          )
        },
      },
      {
        header: "Created Date",
        accessorKey: "createdDate",
        enableColumnFilter: false,
      },
      {
        header: "Time Elapsed",
        accessorKey: "timeElapsed",
        enableColumnFilter: false,
        cell: (cell) => {
          const { timeElapsed } = cell.row.original
          return timeElapsed ? (
            <span>{cell.getValue()}</span>
          ) : (
            <div className="w-full h-full">
              <img
                src={loadingIcon}
                className=" animate-spin h-4"
                alt="loading-icon"
              />
            </div>
          )
        },
      },
      {
        header: "Status",
        enableColumnFilter: false,
        accessorKey: "status",
        cell: (cell) => {
          const status = cell.getValue().toLowerCase()
          return (
            <span className={getStatusBadge(status)}>{cell.getValue()}</span>
          )
        },
      },
      {
        header: "",
        accessorKey: " ",
        enableColumnFilter: false,
        cell: (cell) => {
          const { status } = cell.row.original
          return (
            <div className="d-flex justify-content-between">
              {cell.getValue() || ""}
              <i
                className="ri-skip-forward-mini-line"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (status === STATUS_READY) {
                    setOpenTab({
                      id: cell.row.original.id,
                      taskId: cell.row.original.taskId,
                      taskName: cell.row.original.taskName,
                      taskKeyName: cell.row.original.taskKeyName,
                      actionPath: cell.row.original.actionPath,
                      open: true,
                      label:
                        cell.row.original.taskKeyName ===
                          TASK_APPROVE_CREATE_BUNDLE ||
                        cell.row.original.taskKeyName === TASK_UPLOAD_BUNDLE
                          ? APPROVE
                          : START,
                      actionName: cell.row.original.actionName,
                      workflowId: cell.row.original.workflowId,
                    })
                  }
                }}
              ></i>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <TableContainer
        columns={columns}
        data={filteredData} // Use the filtered data
        customPageSize={20}
        SearchPlaceholder="Search..."
        showPagination={false}
      />
    </React.Fragment>
  )
}
