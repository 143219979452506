import { useMemo } from "react"
import {
  FormatCurrency,
  FormatName,
  FormatNumber,
  getStatusBadge,
} from "helpers/Formatters/Format"
import {
  US_DOLLAR_SYMBOL,
  TASK_CHECK_ACH,
  TASK_CHECK_MONEYGRAM,
  TASK_CHECK_DEBITCARD,
  TASK_CHECK_WAGEDEDUCTION,
} from "Components/constants/constants"


export const GetColumns = (isOutbound, isCreateBundle) => {
  return useMemo(() => {
    const columnsMapping = {
      outbound: [
        {
          header: "Product Type",
          accessorKey: "product_type",
          enableColumnFilter: false,
          cell: (cell) => {
            const value = cell.getValue()
            return <span>{FormatName(value)}</span>
          },
        },
        { header: "Count", accessorKey: "count", enableColumnFilter: false },
        {
          header: "Return Count",
          accessorKey: "return_count",
          enableColumnFilter: false,
          cell: (cell) => {
            const value = cell.getValue()
            return value === "" ? "-" : <span>{FormatNumber(value)}</span>
          },
        },
        {
          header: "Total Return Amount",
          accessorKey: "total_return_amount",
          enableColumnFilter: false,
          cell: (cell) => {
            const value = cell.getValue()
            return value === "" ? (
              "-"
            ) : (
              <span>
                {US_DOLLAR_SYMBOL} {FormatCurrency(value)}
              </span>
            )
          },
        },
        {
          header: "Total Amount",
          accessorKey: "total_amount",
          enableColumnFilter: false,
          cell: (cell) => {
            const value = cell.getValue()
            return value === "" ? (
              "-"
            ) : (
              <span>
                {US_DOLLAR_SYMBOL} {FormatCurrency(value)}
              </span>
            )
          },
        },
        {
          header: "Total Fees",
          accessorKey: "total_fees",
          enableColumnFilter: false,
          cell: (cell) => {
            const value = cell.getValue()
            return value === "" ? (
              "-"
            ) : (
              <span>
                {US_DOLLAR_SYMBOL} {FormatCurrency(value)}
              </span>
            )
          },
        },
        {
          header: "Completed Amount",
          accessorKey: "completed_amount",
          enableColumnFilter: false,
          cell: (cell) => {
            const value = cell.getValue()
            return value === "" ? (
              "-"
            ) : (
              <span>
                {US_DOLLAR_SYMBOL} {FormatCurrency(value)}
              </span>
            )
          },
        },
        {
          header: "Status",
          accessorKey: "status",
          enableColumnFilter: false,
          cell: (cell) => {
            const status = cell?.getValue()?.toLowerCase()
            return (
              <span className={getStatusBadge(status)}>{cell.getValue()}</span>
            )
          },
        },
      ],
      outbound_create_bundle: [
        {
          header: "ACH Bundle ID",
          accessorKey: "ach_bundle_id",
          enableColumnFilter: false,
        },
        { header: "Processor Name", accessorKey: "processor_name", enableColumnFilter: false },
        {
          header: "Credit Amount",
          accessorKey: "credit_amount",
          enableColumnFilter: false,
          cell: (cell) => {
            const value = cell.getValue()
            return value === 0 ? "-" : <span>{FormatNumber(value)}</span>
          },
        },
        {
          header: "Transaction Count",
          accessorKey: "transaction_count",
          enableColumnFilter: false,
        },
        {
          header: "Payment Processor ID",
          accessorKey: "payment_processor_id",
          enableColumnFilter: false,
        },
        {
          header: "Refund Count",
          accessorKey: "refund_count",
          enableColumnFilter: false,
        },
      ],

      default:[
        {
          header: "Total Transactions",
          accessorKey: "total_transactions",
          enableColumnFilter: false,
          cell: (cell) => (
            <span className="text-secondary">
              {FormatNumber(cell.getValue())}
            </span>
          ),
        },
        {
          header: "Total Amount",
          accessorKey: "total_amount",
          enableColumnFilter: false,
          cell: (cell) => (
            <span className="text-secondary">
              {US_DOLLAR_SYMBOL} {FormatCurrency(cell.getValue())}
            </span>
          ),
        },
        {
          header: "Total Fees",
          accessorKey: "total_fees",
          enableColumnFilter: false,
          cell: (cell) => (
            <span className="text-secondary">
              {US_DOLLAR_SYMBOL} {FormatCurrency(cell.getValue())}
            </span>
          ),
        },
        {
          header: "Refund Count",
          accessorKey: "refund_count",
          enableColumnFilter: false,
          cell: (cell) => (
            <span className="text-secondary">
              {FormatNumber(cell.getValue())}
            </span>
          ),
        },
        {
          header: "Refund Fee Amount",
          accessorKey: "refund_fee_amount",
          enableColumnFilter: false,
          cell: (cell) => (
            <span className="text-secondary">
              {US_DOLLAR_SYMBOL} {FormatCurrency(cell.getValue())}
            </span>
          ),
        },
      ]
    }

    return [
      ...(!isOutbound ? columnsMapping.default : isCreateBundle? columnsMapping.outbound_create_bundle :columnsMapping.outbound),
    ]
  }, [isOutbound])
}

export const GetTaskColumns = (taskName) => {

  return useMemo(() => {

    const columnsMapping = {    
    [TASK_CHECK_ACH]: [
      {
        header: "Total Transactions",
        accessorKey: "total_transactions",
        enableColumnFilter: false,
        cell: (cell) => (
          <span>
            {FormatNumber(cell.getValue())}
          </span>
        ),
      },
      {
        header: "Total Amount",
        accessorKey: "total_amount",
        enableColumnFilter: false,
        cell: (cell) => (
          <span>
            {US_DOLLAR_SYMBOL} {FormatCurrency(cell.getValue())}
          </span>
        ),
      },
      {
        header: "Total Fees",
        accessorKey: "total_fees",
        enableColumnFilter: false,
        cell: (cell) => (
          <span>
            {US_DOLLAR_SYMBOL} {FormatCurrency(cell.getValue())}
          </span>
        ),
      },
      {
        header: "Refund Count",
        accessorKey: "refund_count",
        enableColumnFilter: false,
        cell: (cell) => (
          <span>
            {FormatNumber(cell.getValue())}
          </span>
        ),
      },
      {
        header: "Refund Fee Amount",
        accessorKey: "refund_fee_amount",
        enableColumnFilter: false,
        cell: (cell) => (
          <span>
            {US_DOLLAR_SYMBOL} {FormatCurrency(cell.getValue())}
          </span>
        ),
      },
    ],    
    [TASK_CHECK_MONEYGRAM]: [
      {
        header: "File ID",
        accessorKey: "moneygram_reconciliation_file_id",
        enableColumnFilter: false,
      },
      {
        header: "Inbound Count",
        accessorKey: "inbound_count",
        enableColumnFilter: false,
      },
      {
        header: "Inbound Amount",
        accessorKey: "inbound_amount",
        enableColumnFilter: false,
        cell: (cell) => {
          const value = cell.getValue()
          return value === 0 ? (
            "-"
          ) : (
            <span>
              {US_DOLLAR_SYMBOL} {FormatCurrency(value)}
            </span>
          )
        },
      },
      {
        header: "Date",
        accessorKey: "date",
        enableColumnFilter: false,
      
      },
    ],
    [TASK_CHECK_WAGEDEDUCTION]: [
      {
        header: "Wage Bundle Id",
        accessorKey: "wage_bundle_id",
        enableColumnFilter: false,
      },
      {
        header: "Total Amount",
        accessorKey: "total_amount",
        enableColumnFilter: false,
        cell: (cell) => (
          <span>
            {US_DOLLAR_SYMBOL} {FormatCurrency(cell.getValue())}
          </span>
        ),
      },
      {
        header: "Fee Amount",
        accessorKey: "fee_amount",
        enableColumnFilter: false,
        cell: (cell) => (
          <span>
            {US_DOLLAR_SYMBOL} {FormatCurrency(cell.getValue())}
          </span>
        ),
      },
      {
        header: "Trustee Amount",
        accessorKey: "trustee_amount",
        enableColumnFilter: false,
        cell: (cell) => (
          <span>
            {US_DOLLAR_SYMBOL} {FormatCurrency(cell.getValue())}
          </span>
        ),
      },
      { header: "Count", accessorKey: "count", enableColumnFilter: false },     
    ],
    [TASK_CHECK_DEBITCARD]: [
      {
        header: "Bundle ID",
        accessorKey: "debit_card_bundle_id",
        enableColumnFilter: false,
      },
      {
        header: "Outbound Amount",
        accessorKey: "outbound_amount",
        enableColumnFilter: false,
        cell: (cell) => {
          const value = cell.getValue()
          return value === 0 ? (
            "-"
          ) : (
            <span>
              {US_DOLLAR_SYMBOL} {FormatCurrency(value)}
            </span>
          )
        },
      },
      {
        header: "Successful Fees Count",
        accessorKey: "successful_fee_only_payment_count",
        enableColumnFilter: false,
        cell: (cell) => {
          const value = cell.getValue()
          return value === "" ? (
            "-"
          ) : (
            <span>
              {FormatNumber(value)}
            </span>
          )
        },
      },
      {
        header: "Successful Inbound Amount",
        accessorKey: "successful_fee_inbound_amount",
        enableColumnFilter: false,
        cell: (cell) => {
          const value = cell.getValue()
          return value === 0 ? (
            "-"
          ) : (
            <span>
              {US_DOLLAR_SYMBOL} {FormatCurrency(value)}
            </span>
          )
        },
      },
      {
        header: "Successful Inbound Count",
        accessorKey: "successful_inbound_count",
        enableColumnFilter: false,
        cell: (cell) => {
          const value = cell.getValue()
          return value === 0 ? "-" : <span>{FormatNumber(value)}</span>
        },
      },
      {
        header: "Successful Outbound Amount",
        accessorKey: "successful_outbound_amount",
        enableColumnFilter: false,
        cell: (cell) => {
          const value = cell.getValue()
          return value === 0 ? (
            "-"
          ) : (
            <span>
              {US_DOLLAR_SYMBOL} {FormatCurrency(value)}
            </span>
          )
        },
      },
      {
        header: "Successful Trustee Inbound Amount",
        accessorKey: "successful_trustee_inbound_amount",
        enableColumnFilter: false,
        cell: (cell) => {
          const value = cell.getValue()
          return value === 0 ? (
            "-"
          ) : (
            <span>
              {US_DOLLAR_SYMBOL} {FormatCurrency(value)}
            </span>
          )
        },
      },
      {
        header: "Date",
        accessorKey: "date",
        enableColumnFilter: false,
        cell: (cell) => {
          const date = new Date(cell.getValue())
          const formattedDate = date
            .toLocaleDateString("en-GB")
            .replace(/\//g, "-")
          return <span>{formattedDate}</span>
        },
      },
    ],
      
    }

    return columnsMapping[taskName] // Return directly, handle undefined case
  }, [taskName]) // Dependency array is crucial
}