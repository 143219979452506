import React, { useMemo } from "react"
// import utilities
import { getStatusBadge } from "helpers/Formatters/Format"
// import common reusable components
import TableContainer from "../../Components/Common/Table/TableContainer"

export const WorkflowList = ({ workflowData }: any) => {
  // Table headings
  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
      },
      {
        header: "Date",
        accessorKey: "date",
        enableColumnFilter: false,
      },
      {
        header: "Start Time",
        accessorKey: "start_time",
        enableColumnFilter: false,
      },
      {
        header: "End Time",
        accessorKey: "end_time",
        enableColumnFilter: false,
      },
      {
        header: "Processor",
        accessorKey: "processor",
        enableColumnFilter: false,
      },
      {
        header: "Current Task",
        accessorKey: "current_Task",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        enableColumnFilter: false,
        accessorKey: "status",
        cell: (cell: any) => {
          const status = cell.getValue().toLowerCase()
          return (
            <span className={getStatusBadge(status)}>{cell.getValue()}</span>
          )
        },
      },
    ],
    []
  )

  // Renders the Table component
  return (
    <React.Fragment>
      <TableContainer
        columns={columns || []}
        data={workflowData || []}
        customPageSize={5}
        SearchPlaceholder="Search..."
        showPagination={false}
        clickable={true}
        navigateTo="/payment-processing"
      />
    </React.Fragment>
  )
}
