import { createSlice } from "@reduxjs/toolkit"

// Initial state
const initialState = {
    inboundDates: [], // Single global state to hold all dates in the active window
    error: null,
}

const inboundSummarySlice = createSlice({
    name: "inboundsummary",
    initialState,
    reducers: {
        addDates: (state, action) => {
            state.inboundDates =action?.payload
        },
    },
})

// Export actions and reducer
export const { addDates } = inboundSummarySlice.actions
export default inboundSummarySlice.reducer
