import { DateTime } from "ts-luxon"

// Endpoints
export const GET_WORKFLOWS="/workflow/workflow"
export const GET_WORKLFOW_SSE="/workflow/status"
export const GET_WORKFLOW_ACTION_SSE="workflow/actions/stream"
export const GET_WORKFLOW_STATISTICS="/workflow/getStatistics?workflowid="
export const GET_WORKFLOW_ACTIONS="/workflow/getActions"
export const GET_TICKET_SSE="/tickets/status"
export const GET_TICKET_LIST="/tickets/getTickets"
export const GET_NOTIFICATION_UPDATEREADBY="/notification/updateReadby"
export const CAMUNDA_INBOUND="/inbound"
export const GET_NOTIFICATION_PER_USER="/notification/userNotifications?user"
export const ZERO_OUT_SUMMARY_REPORT="/zero-out/reports?date="
export const ZERO_OUT_INBOUND_REPORT="/zero-out/reports/inbound-day-summary?"
export const ZERO_OUT_INBOUND_REPORT_TRANSACTIONS="/zero-out/reports/inbound-day-txns"
export const ZERO_OUT_INBOUND_REPORT_PAGE_SIZE=10
export const ZERO_OUT_TIMELINE="/zero-out/timeline"
export const ZERO_OUT_HOLIDAYS="/zero-out/holidays"
export const SSE_PATH="/workflow/sse_updates?user="
export const FEES_CALCULATION = "/zero-out/fees?start_date=${start_date}&end_date=${end_date}"
export const STATS_CREATE_BUNDLE="/workflow/aggregated_statistics?workflow_id="
export const HEALTH_CHECK="/health-check"
export const PERMISSIONS_FOR_ROLE="/permissions/role"

// Task Key Names
export const TASK_CREATE_BUNDLE = "create_bundle"
export const TASK_APPROVE_CREATE_BUNDLE = "approve_create_bundle"
export const TASK_UPLOAD_PAYMENT_BUNDLE = "upload_payment_bundle"
export const TASK_UPLOAD_BUNDLE = "upload_bundle"
export const TASK_RESTART_CREATE_BUNDLE = "restart_create_bundle"
export const TASK_RESTART_UPLOAD_PAYMENT_BUNDLE = "restart_upload_payment_bundle"
export const OUTBOUND_ACH="outbound_ach"
export const INBOUND="inbound ach"
export const RESTART_ACH="Restart Check ACH"
export const RESTART_MONEY_GRAM="Restart Check Money Gram"
export const RESTART_DEBIT_CARD="Restart Check Debit Card"
export const RESTART_WAGE_DEDUCTION="Restart Check Wage Deduction"
export const APPROVE_UPLOAD_BUNDLE="Approve Upload Bundle"
export const CHECK_ACH="Check ACH"
export const CHECK_MONEY_GRAM="Check Money Gram"
export const CHECK_WAGE_DEDUCTION="Check Wage Deduction"
export const CHECK_DEBIT_CARD="Check Debit Card"
export const OUTBOUND_ACH_WORKFLOW="outbound_ach_workflow"

// Workflow States
export const STATUS_READY="ready"
export const STATUS_IN_PROGRESS="in_progress"
export const STATUS_COMPLETED="completed"
export const STATUS_FAILED="failed"
export const STATUS_SCHEDULED="scheduled"
export const STATUS_PENDING="pending"
export const STATUS_POSTED="posted"

// Zero Out Report States
export const STATUS_ZO_PENDING="Pending"
export const STATUS_ZO_POSTED="Posted"
export const STATUS_ZO_RETURNED="Returned"
export const STATUS_ZO_DEBTOR_REFUNDED="Debtor Refunded"
export const STATUS_ZO_SETTLED_FOR_TRUSTEE="Settled for Trustee"
export const STATUS_ZO_SETTLED_FOR_TFS="Settled for TFS"
export const STATUS_ZO_SETTLED_FOR_BANK="Settled for Bank"
export const STATUS_ZO_RESERVE_TRANSFERRED="Reserve Transferred"
export const STATUS_ZO_DISHONOURED="Dishonored"
export const STATUS_ZO_COMPLETED="Completed"

export const APPROVE="Approve"
export const START="Start"

// Ticket States
export const TICKET_SOLVED = "solved"
export const TICKET_OPEN = "open"

// Transaction Types
export const INBOUND_ACH="ACH" // Request change
export const MONEY_GRAM="MONEYGRAM" // Request change
export const WAGE_DEDUCTION="WAGE" // Request change
export const DEBIT_CARD="DEBIT" // Request change

// API Response Messages
export const API_SUCCESS_RESPONSE="Success"
export const ZERO_OUT_NOT_FOUND_ERROR="Starting Balance Not found"

// Currency
export const US_DOLLAR_SYMBOL="$"

// Zero Out 
export const TAB_IDS = [INBOUND_ACH, MONEY_GRAM, WAGE_DEDUCTION, DEBIT_CARD]
export const MAX_DATE = DateTime.now()
  .setZone("America/New_York") // Ensures accurate EDT time

// Outbound 
export const TASK_START_OUTBOUND="start_outbound"
export const TASK_CHECK_ACH="check_ach"
export const TASK_CHECK_MONEYGRAM="check_money_gram"
export const TASK_CHECK_WAGEDEDUCTION="check_wage_deduction"
export const TASK_CHECK_DEBITCARD="check_debit_card"

export const OUTBOUND_TASKS = [TASK_CHECK_ACH, TASK_CHECK_MONEYGRAM, TASK_CHECK_DEBITCARD, TASK_CHECK_WAGEDEDUCTION]
export const RestartTasks = [RESTART_DEBIT_CARD, RESTART_MONEY_GRAM,RESTART_ACH,RESTART_WAGE_DEDUCTION]
	
export const AMOUNT="amount"
export const FEE="fee"
export const INBOUND_IDS="inbound_ids"
export const EWAGE_TRANSACTIONS="ewage_transactions"
export const ESQUIRE_COSTS="esquire_costs"

