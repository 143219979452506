import { DateTime } from "ts-luxon"

// Helper function to check if a date is a weekend (Saturday or Sunday)
const isWeekend = (date: DateTime) => date.weekday === 6 || date.weekday === 7

// Main function to calculate two business days before the given date
export const calculateCustomBusinessDaysBefore = (
  currentDate: DateTime,
  holidays: Date[],
  customBusinessDays: number
) => {
  let targetDate = currentDate
  let businessDaysCount = 0

  // Convert holidays into a Set of ISO date strings for fast lookup
  const holidaySet = new Set(
    holidays?.map((holiday) => DateTime.fromJSDate(holiday).toISODate())
  )

  // Loop until custom business days are found
  while (businessDaysCount < customBusinessDays) {
    targetDate = targetDate.minus({ days: 1 })

    // Skip weekends (Saturday/Sunday) or holidays
    if (isWeekend(targetDate) || holidaySet.has(targetDate.toISODate())) {
      continue // Skip the invalid day
    }

    // Increment the business days count if it's a valid business day
    businessDaysCount++
  }
  return targetDate
}
