import { DateTime } from "ts-luxon"
import React, { useState, useEffect } from "react"
import {
  STATUS_COMPLETED,
  STATUS_FAILED,
  STATUS_IN_PROGRESS,
} from "Components/constants/constants"

const Timer: React.FC<any> = ({ startedTime, allocatedTime, status }) => {
  // Convert the startedTime to milliseconds
  const etTime: DateTime = DateTime.fromISO(startedTime, {
    zone: "America/New_York",
  })

  // Step 2: Convert the ET time to UTC
  const startTimeUTC = etTime.toUTC()

  // Function to calculate the remaining time left based on ET
  const calculateTimeLeft = () => {
    // Get current time in milliseconds since the epoch
    const currentTime = DateTime.utc()

    // Calculate elapsed time in seconds
    const secondsDifference = currentTime.diff(startTimeUTC, "seconds").seconds

    // Calculate remaining time, ensuring it doesn't go negative
    return Math.max(allocatedTime - secondsDifference, 0)
  }

  // Set the initial state for time left
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())
  const [strokeDashOffSet, setStrokeDashOffSet] = useState(0)
  const [gradientStopOffset, setGradientStopOffset] = useState(0)

  // Update the time left every second
  useEffect(() => {
    if (status && status?.toLowerCase() !== STATUS_COMPLETED) {
      // Update the timer every 1 second
      const timerId = setInterval(() => {
        setTimeLeft(calculateTimeLeft())
      }, 1000)

      // Cleanup interval on component unmount
      return () => clearInterval(timerId)
    }
  }, [startTimeUTC, allocatedTime, status])

  // Convert seconds to mm:ss format
  const formatTime = () => {
    const hours = Math.floor(timeLeft / 3600) // Calculate hours
    const minutes = Math.floor((timeLeft % 3600) / 60) // Remaining minutes after accounting for hours
    const secs = Math.floor(timeLeft % 60) // Remaining seconds

    if (hours > 0) {
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${secs.toString().padStart(2, "0")}` // Format as hh:mm:ss
    } else {
      return `${minutes.toString().padStart(2, "0")}:${secs
        .toString()
        .padStart(2, "0")}` // Format as mm:ss
    }
  }

  useEffect(() => {
    if (status?.toLowerCase() === STATUS_COMPLETED) {
      setStrokeDashOffSet(0) // Full circle when status is "Completed"
      setTimeLeft(0) // Show 00:00 when completed
    } else if (timeLeft) {
      const strokeDashoffset = circumference * (1 - timeLeft / allocatedTime) // Calculate offset as time decreases
      setStrokeDashOffSet(strokeDashoffset)
      // Calculate the gradient stop offset based on the remaining time
      const gradientStopOffset = (timeLeft / allocatedTime) * 100
      setGradientStopOffset(gradientStopOffset)
    }
  }, [timeLeft, status, allocatedTime])

  // Calculate the stroke-dasharray and stroke-dashoffset values for the SVG circle
  const radius = 50 // Radius of the circle
  const circumference = 2 * Math.PI * radius // Circumference of the circle

  // Define the gradient colors
  const gradientColors = ["#37576F"]

  // Conditionally set the stroke color based on status
  const strokeColor =
    status?.toLowerCase() === "completed" ? "#e6e6e6" : "url(#gradient)"

  return (
    <div style={{ width: "90px", height: "90px", position: "relative" }}>
      <svg width="100%" height="100%" viewBox="0 0 120 120">
        {/* Background circle (gray) */}
        <circle
          cx="60"
          cy="60"
          r={radius}
          stroke="#e6e6e6"
          strokeWidth="6"
          fill="none"
        />
        {/* Foreground circle (the one that becomes white gradually or fully filled when completed) */}
        <circle
          cx="60"
          cy="60"
          r={radius}
          stroke={strokeColor} // Conditionally apply stroke color
          strokeWidth="6"
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashOffSet}
          strokeLinecap="butt"
          transform="rotate(-90 60 60)"
        />
        {/* Gradient definition */}
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor={gradientColors[0]} />
            <stop
              offset={`${gradientStopOffset}%`}
              stopColor={gradientColors[0]}
            />
          </linearGradient>
        </defs>
      </svg>
      {!timeLeft && status?.toLowerCase() === STATUS_IN_PROGRESS && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
        >
          <div className="animate-pulse">
            <i className="rmx ri-more-line text-2xl"></i>
          </div>
        </div>
      )}
      {timeLeft && status?.toLowerCase() !== STATUS_COMPLETED ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
        >
          <div className="text-sm font-semibold">{formatTime()}</div>
          <div style={{ fontSize: "10px" }}>Left</div>
        </div>
      ) : (
        (status?.toLowerCase() === STATUS_COMPLETED ||
          status?.toLowerCase() === STATUS_FAILED) && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
            }}
          >
            <div
              className={`text-sm font-semibold text-gray-500 {${
                status?.toLowerCase() === "failed" &&
                "text-red-400 animate-pulse"
              }}`}
            >
              00:00
            </div>
            <div style={{ fontSize: "10px" }}>Left</div>
          </div>
        )
      )}
    </div>
  )
}

export default Timer
