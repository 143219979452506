import { GET_NOTIFICATION_PER_USER } from "Components/constants/constants";
import { determineBaseUrlAndPerformApiCall } from "slices/interceptor/interceptor";
import { fetchNotificationsSuccess, fetchNotificationsFailure, fetchNotificationsStart } from "./reducer";

export const fetchNotifications = () => async (dispatch) => {
    dispatch(fetchNotificationsStart())

    try {
        const username = sessionStorage.getItem("username")

        const response = await determineBaseUrlAndPerformApiCall(`${GET_NOTIFICATION_PER_USER}=${username}`, 'GET')
        if (response.error && response.error.code === 'NotFound') {
            dispatch(fetchNotificationsSuccess([]));
        }
        else {
            dispatch(fetchNotificationsSuccess(response));
        }
    }
    catch (error) {
        dispatch(fetchNotificationsFailure(error.message))
    }
}