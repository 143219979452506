import React from "react"
import { Link } from "react-router-dom"

// Import images
import logoSm from "../assets/images/logo-sm.png"
import logoDark from "../assets/images/logo-dark.png"
import logoLight from "../assets/images/logo-light.png"

// Import Components
import FullScreenDropdown from "../Components/Common/FullScreenDropdown"
import NotificationDropdown from "../Components/Common/NotificationDropdown"
import ProfileDropdown from "../Components/Common/ProfileDropdown"
import { ToastContainer } from "react-toastify"
import { changeSidebarVisibility } from "slices/thunks"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

const Header = (headerClass) => {
  const dispatch = useDispatch()

  const selectDashboardData = createSelector(
    (state) => state.Layout.sidebarVisibilitytype,
    (sidebarVisibilitytype) => sidebarVisibilitytype
  )
  const { permissions } = useSelector((state) => state.Permissions)

  // Inside your component
  const sidebarVisibilitytype = useSelector(selectDashboardData)

  const toogleMenuBtn = () => {
    const windowSize = document.documentElement.clientWidth
    dispatch(changeSidebarVisibility("show"))

    if (windowSize > 767) {
      document.querySelector(".hamburger-icon").classList.toggle("open")
    }

    //For collapse vertical and semibox menu
    if (
      sidebarVisibilitytype === "show" &&
      document.documentElement.getAttribute("data-layout") === "vertical"
    ) {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove("vertical-sidebar-enable")
        document.documentElement.getAttribute("data-sidebar-size") === "sm"
          ? document.documentElement.setAttribute("data-sidebar-size", "")
          : document.documentElement.setAttribute("data-sidebar-size", "sm")
      } else if (windowSize > 1025) {
        document.body.classList.remove("vertical-sidebar-enable")
        document.documentElement.getAttribute("data-sidebar-size") === "lg"
          ? document.documentElement.setAttribute("data-sidebar-size", "sm")
          : document.documentElement.setAttribute("data-sidebar-size", "lg")
      } else if (windowSize <= 767) {
        document.body.classList.add("vertical-sidebar-enable")
        document.documentElement.setAttribute("data-sidebar-size", "lg")
      }
    }

    //Two column menu
    if (document.documentElement.getAttribute("data-layout") === "twocolumn") {
      document.body.classList.contains("twocolumn-panel")
        ? document.body.classList.remove("twocolumn-panel")
        : document.body.classList.add("twocolumn-panel")
    }
  }

  const notificationsPermission = permissions?.resource_name?.find(
    (resource) => resource.resource_name === "notifications"
  )

  return (
    <React.Fragment>
      <ToastContainer />
      <header id="page-topbar" className={headerClass}>
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box horizontal-logo">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logoSm} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="17" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoSm} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLight} alt="" height="17" />
                  </span>
                </Link>
              </div>
              <button
                onClick={toogleMenuBtn}
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger shadow-none"
                id="topnav-hamburger-icon"
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>
            </div>

            <div className="d-flex align-items-center">
              {/* FullScreenDropdown */}
              <FullScreenDropdown />

              {/* NotificationDropdown */}
              {notificationsPermission && <NotificationDropdown />}

              {/* ProfileDropdown */}
              <ProfileDropdown />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

export default Header
