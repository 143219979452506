// components/TableContainer/index.tsx
import React from "react"
import { DateTime } from "ts-luxon"
import { formatDate } from "helpers/Formatters/DateFormatter"
import { FormatCurrency, FormatNumber } from "helpers/Formatters/Format"
import {
  US_DOLLAR_SYMBOL,
  AMOUNT,
  FEE,
  EWAGE_TRANSACTIONS,
  INBOUND_IDS,
  ESQUIRE_COSTS,
} from "Components/constants/constants"
import MonthlyTable from "./MonthlyTable"
import WeeklyTable from "./weeklyTable"

interface TableProps {
  tableData: {
    settled_date: any
    fees_collected: any
    esquire_costs_sub_total: any
    tfs_amount: any
    count: Record<any, any>
    lost_money: Record<any, any>
    esquire_costs: Record<any, any>
  }[]
  monthlyData: {
    monthlyFeesCollected: any
    monthlyEsquireCosts: any
    monthlyTFSAmount: any
    start_date: any
    end_date: any
  }
}
const TableContainer: React.FC<{
  tableData: TableProps["tableData"]
  monthlyData: TableProps["monthlyData"]
}> = ({ tableData, monthlyData }) => {
  const tableDataWithNumericCounts = tableData
    .map((item) => ({
      ...item,
      count: Object.fromEntries(
        Object.entries(item?.count).map(([key, value]) => [key, Number(value)])
      ),
    }))
    .sort(
      (a, b) =>
        DateTime.fromISO(a.settled_date, {
          zone: "America/New_York",
        }).toMillis() -
        DateTime.fromISO(b.settled_date, {
          zone: "America/New_York",
        }).toMillis()
    )
  const formatKey = (key: string) => {
    if (key === EWAGE_TRANSACTIONS) {
      return "eWage Transaction"
    } else {
      return key
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase())
    }
  }

  const FeesTable = (
    title: string,
    dataKey: keyof TableProps["tableData"][0],
    isGreen?: boolean
  ) => {
    const chunkArray = (arr: string[], chunkSize: number) => {
      const chunks = []
      for (let i = 0; i < arr.length; i += chunkSize) {
        chunks.push(arr.slice(i, i + chunkSize))
      }
      return chunks
    }

    return (
      <table className="table-nowrap min-w-full border-collapse border-1 border-[#B6B6B6] rounded-lg">
        <thead>
          <tr className="bg-white rounded-lg">
            <th className="p-2 text-sm text-[#495057] bg-white min-w-[175px] border-b">
              {title}
            </th>
            <td
              className="p-1 bg-white min-w-[180px] border-b"
              colSpan={tableDataWithNumericCounts.length}
            ></td>
          </tr>
        </thead>
        <tbody>
          {Object.keys(tableDataWithNumericCounts[0][dataKey] || {}).map(
            (key, index, arr) => (
              <tr
                key={key}
                className={
                  index === arr.length - 1 && isGreen
                    ? "bg-[#D9E3C0]"
                    : index % 2 === 0
                      ? "bg-white"
                      : "bg-[#F4F3F2]"
                }
              >
                <td className="p-2 border-r text-gray-700 capitalize font-bold min-w-[175px]">
                  {formatKey(key)}
                </td>
                {tableDataWithNumericCounts.map((item: any, tableIndex) => (
                  <td
                    key={tableIndex}
                    className="p-2 border-r text-right text-[#000000] min-w-[175px]"
                  >
                    {dataKey === ESQUIRE_COSTS ||
                      key?.toLowerCase().includes(AMOUNT) ||
                      key.toLowerCase().includes(FEE) ? (
                      <div className="flex justify-between items-center min-w-[175px]">
                        <span className="float-left">{US_DOLLAR_SYMBOL}</span>
                        <span>
                          {item[dataKey][key]
                            ? FormatCurrency(item[dataKey][key])
                            : "-"}
                        </span>
                      </div>
                    ) : key.toLowerCase().includes(INBOUND_IDS) ? (
                      <div className="flex flex-col justify-end items-end min-w-[175px]">
                        {item[dataKey][key] ? (
                          chunkArray(item[dataKey][key].split(","), 2)?.map(
                            (chunk, chunkIndex) => (
                              <div
                                key={chunkIndex}
                                className="flex justify-end gap-2"
                              >
                                {chunk.map((id: string, idIndex: number) => (
                                  <span key={idIndex} className="text-right ">
                                    {id.trim()}
                                  </span>
                                ))}
                              </div>
                            )
                          )
                        ) : (
                          <span>-</span>
                        )}
                      </div>
                    ) : key.toLowerCase().includes(EWAGE_TRANSACTIONS) ? (
                      <span>
                        {item[dataKey][key]
                          ? FormatCurrency(item[dataKey][key])
                          : "-"}
                      </span>
                    ) : (
                      <div className="flex justify-between items-center min-w-[175px]">
                        <span className="float-left"></span>
                        <span>
                          {item[dataKey][key]
                            ? FormatNumber(item[dataKey][key])
                            : "-"}
                        </span>
                      </div>
                    )}
                  </td>
                ))}
              </tr>
            )
          )}
        </tbody>
      </table>
    )
  }
  return (
    <div className="overflow-x-auto bg-gray-100 py-3 w-full">
      <div className="min-w-fit flex flex-col gap-3">
        <MonthlyTable monthlyData={monthlyData} />
        {tableDataWithNumericCounts.length > 0 && (
          <>
            <table className="table-nowrap min-w-full border-collapse border-1 border-[#B6B6B6]">
              <tbody>
                <tr>
                  <td className="p-2 border-r border-[#B6B6B6] bg-white min-w-[175px]"></td>
                  {tableDataWithNumericCounts.map((item, index) => {
                    const date = DateTime.fromISO(item.settled_date, {
                      zone: "America/New_York",
                    })
                    const dayOfWeek = date.toFormat("cccc")
                    return (
                      <td
                        key={index}
                        className="p-2 border-r border-l border-[#B6B6B6] bg-white text-black min-w-[175px]"
                      >
                        <div className="flex flex-col justify-center items-center min-w-[175px]">
                          <span className="text-sm text-black">
                            {dayOfWeek}
                          </span>
                        </div>
                      </td>
                    )
                  })}
                </tr>
                <tr>
                  <td className="p-2 border-r bg-[#F4F3F2] font-bold min-w-[175px]">
                    <div className="flex items-center min-w-[160px]">
                      <span>Settled Date</span>
                    </div>
                  </td>
                  {tableDataWithNumericCounts.map((item, index) => {
                    const date = DateTime.fromISO(item.settled_date, {
                      zone: "America/New_York",
                    })
                    return (
                      <td
                        key={index}
                        className="p-2 border-r border-l border-[#B6B6B6] bg-[#F4F3F2] text-[#000000] min-w-[175px]"
                      >
                        <div className="flex flex-col justify-center items-center min-w-[175px]">
                          <span>{formatDate(date.toISO())}</span>
                        </div>
                      </td>
                    )
                  })}
                </tr>
              </tbody>
            </table>
            <WeeklyTable
              tableData={tableDataWithNumericCounts}
              title="FEES COLLECTED"
              dataKey="fees_collected"
              rowtitle="Fees Collected"
              colorClass="bg-[#C8C8C8]"
            />
            <WeeklyTable
              tableData={tableDataWithNumericCounts}
              title="ESQUIRE COSTS"
              dataKey="esquire_costs_sub_total"
              rowtitle="Sub Total"
              colorClass="bg-[#D9E3C0]"
            />
            <WeeklyTable
              tableData={tableDataWithNumericCounts}
              title="TFS AMOUNT"
              dataKey="tfs_amount"
              rowtitle="TFS Amount"
              colorClass="bg-[#B0DBFF]"
            />
            {FeesTable("COUNT", "count")}
            {FeesTable("LOST MONEY", "lost_money")}
            {FeesTable("ESQUIRE COSTS", "esquire_costs", true)}
          </>
        )}
      </div>
    </div>
  )
}

export default TableContainer