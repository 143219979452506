import { loginSuccess, logoutUserSuccess, apiError } from "./reducer"

export const loginUser = () => async (dispatch: any) => {
  dispatch(loginSuccess())
}

export const logoutUser = () => async (dispatch: any) => {
  try {
    sessionStorage.clear()
    localStorage.clear()
    dispatch(logoutUserSuccess())
  } catch (error: any) {
    dispatch(apiError(error))
  }
}
