import { useEffect, useState } from "react"

export const DebouncedInput = ({
  value: initialValue,
  columnId,
  onSearch,
  ...props
}: {
  value: string
  columnId: string
  onSearch: any
} & Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "onChange" | "value"
>) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearch(columnId, value) // Trigger search
    }
  }

  return (
    <input
      {...props}
      value={value}
      id={`search-bar-${columnId}`}
      className="form-control p-0 px-2 py-1 w-full min-w-20 search"
      onChange={(e) => setValue(e.target.value)} // Update the local state
      onKeyDown={handleKeyDown}
    />
  )
}

export const Filter = ({
  column,
  onSearch,
  filterValue,
}: {
  column: any
  onSearch: any
  filterValue: any
}) => {
  return (
    <>
      <DebouncedInput
        type="text"
        value={filterValue}
        placeholder={" 🔍 Search"}
        className="w-16 border shadow rounded"
        columnId={column.id}
        onSearch={onSearch}
      />
      <div className="h-1" />
    </>
  )
}

interface DropDownValueProps {
  values: string[] // Explicitly define the type of `values`
  onSearch: (key: string, value: string) => void // Define the callback signature
  filterValue: string // Current filter value
}

export const DropDownValue: React.FC<DropDownValueProps> = ({
  values,
  onSearch,
  filterValue,
}) => {
  const [selectedValue, setSelectedValue] = useState<string>(filterValue)

  // Update selectedValue when filterValue changes (e.g., from parent)
  useEffect(() => {
    setSelectedValue(filterValue)
  }, [filterValue])

  // Handle item selection
  const handleSelectItem = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = event.target.value
    setSelectedValue(selected)
    onSearch("status", selected) // Trigger the search callback
  }

  return (
    <select
      className="min-w-28 p-0 px-1 py-1 w-full rounded border block mb-1"
      name="states"
      value={selectedValue}
      onChange={handleSelectItem}
    >
      {/* Placeholder option */}
      <option value="">Select an option</option>
      {/* Dynamically render options */}
      {values?.map((item, index) => (
        <option key={index} value={item}>
          {item}
        </option>
      ))}
    </select>
  )
}

export const CustomOption = ({ innerProps, label, data }: any) => (
  <div {...innerProps} className="flex justify-between px-4 py-2">
    {label}
    <div
      style={{
        width: "12px",
        height: "12px",
        borderRadius: "50%",
        backgroundColor: data.color,
        marginRight: "8px",
      }}
    />
  </div>
)

// Custom multi-value component to render selected options with color badges
export const CustomMultiValue = ({ data, removeProps }: any) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      margin: "2px",
      padding: "4px",
      backgroundColor: "#f0f0f0",
      borderRadius: "4px",
    }}
  >
    <div
      style={{
        width: "12px",
        height: "12px",
        borderRadius: "50%",
        backgroundColor: data.color,
        marginRight: "8px",
      }}
    />
    {data.label}
    <button
      {...removeProps} // This handles the removal of the option
      onClick={removeProps.onClick} // Trigger the removal
    >
      <i className="pl-1 font-semibold ri-close-fill"></i>
    </button>
  </div>
)

// Sorting Handler
export const handleSortClick = (columnId: string, table: any, sort: any, onSort: any) => {
  const column = table.getColumn(columnId)

  // Check if the column allows sorting
  if (column?.columnDef?.enableSorting === false) {
    return // Prevent sorting if not enabled
  }

  // If no column has been sorted yet (initial state), sort in ascending order
  if (!sort?.fieldName) {
    onSort(columnId, "asc") // Inform the parent component that the column is now sorted asc.
    table.setSorting([{ id: columnId, desc: false }]) // Ascending order
  } else {
    // If the current column is already the one being sorted, toggle sorting direction
    if (sort?.fieldName === columnId) {
      switch (sort.value) {
        case "asc":
          onSort(columnId, "desc") // Inform the parent component that the column is now sorted desc.
          table.setSorting([{ id: columnId, desc: true }]) // Sort in descending order
          break
        case "desc":
          onSort(columnId, "none") // Inform the parent component that the column is now not sorted.
          table.setSorting([]) // Clear sorting
          break

        default:
          onSort(columnId, "asc") // Inform the parent component that the column is now sorted asc.
          table.setSorting([{ id: columnId, desc: false }]) // Ascending order
          break
      }
    } else {
      // If a different column is clicked, reset to ascending sort
      onSort(columnId, "asc") // Inform the parent component that the column is now sorted asc.
      table.setSorting([{ id: columnId, desc: false }]) // Sort the new column in ascending order
    }
  }
}
