import React from "react"
import { Routes, Route } from "react-router-dom"

// Layouts
import NonAuthLayout from "../Layouts/NonAuthLayout"
import VerticalLayout from "../Layouts/index"
import AuthProtected from "./AuthProtected"

// Custom Hook for Routes
import useRoutes from "./allRoutes" // Adjust the path as needed

const Index = () => {
  const { authProtectedRoutes, publicRoutes } = useRoutes()

  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route: any, idx: any) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
          />
        ))}
        {authProtectedRoutes.map((route: any, idx: any) => (
          <Route
            path={route.path}
            element={
              <AuthProtected>
                <VerticalLayout>{route.component}</VerticalLayout>
              </AuthProtected>
            }
            key={idx}
          />
        ))}
      </Routes>
    </React.Fragment>
  )
}

export default Index
