// components/MonthlyTable/MonthlyTable.tsx
import React from "react"
import { DateTime } from "ts-luxon"
import { formatDate } from "../../../../helpers/Formatters/DateFormatter"
import { FormatCurrency } from "helpers/Formatters/Format"

interface MonthlyTableProps {
  monthlyData: {
    monthlyFeesCollected: any
    monthlyEsquireCosts: any
    monthlyTFSAmount: any
    start_date: any
    end_date: any
  }
}

const MonthlyTable: React.FC<MonthlyTableProps> = ({ monthlyData }) => {
  const Month = monthlyData?.start_date
    ? DateTime.fromISO(monthlyData.start_date).isValid
      ? DateTime.fromISO(monthlyData.start_date)
          .toFormat("MMMM")
          .toUpperCase()
      : "-"
    : "-"

  return (
    <div className="bg-pink-300 w-full overflow-x-auto">
      {/* Header */}
      <div className="text-left bg-white text-sm font-semibold text-[#495057] border-t border-r border-l border-[#B6B6B6]">
        <div className="mt-1 p-2">
          MONTHLY SUM - {Month} ( Start Date:{" "}
          {monthlyData?.start_date ? formatDate(monthlyData.start_date) : "-"} - End Date:{" "}
          {monthlyData?.end_date ? formatDate(monthlyData.end_date) : "-"} )
        </div>
      </div>

      <div className="overflow-x-auto w-full">
        <div className="grid grid-cols-3 bg-[#F4F3F2] font-bold text-left text-black border-l border-[#B6B6B6] min-w-[700px]">
          <div className="p-2 border-r border-[#B6B6B6] min-w-[200px]">
            Fees Collected
          </div>
          <div className="p-2 border-r border-[#B6B6B6] min-w-[200px]">
            Esquire Costs
          </div>
          <div className="p-2 border-[#B6B6B6] border-r min-w-[200px]">
            TFS Amount
          </div>
        </div>

        <div className="grid grid-cols-3 bg-white text-right text-black border-l border-b border-[#B6B6B6] min-w-[700px]">
          <div className="p-2 border-r justify-between border-[#B6B6B6] min-w-[200px]">
            <span className="float-left">$</span>
            <span>
              {monthlyData?.monthlyFeesCollected
                ? FormatCurrency(monthlyData.monthlyFeesCollected)
                : "-"}
            </span>
          </div>
          <div className="p-2 border-r justify-between border-[#B6B6B6] min-w-[200px]">
            <span className="float-left">$</span>
            <span>
              {monthlyData?.monthlyEsquireCosts
                ? FormatCurrency(monthlyData.monthlyEsquireCosts)
                : "-"}
            </span>
          </div>
          <div className="p-2 justify-between border-[#B6B6B6] border-r min-w-[200px]">
            <span className="float-left">$</span>
            <span>
              {monthlyData?.monthlyTFSAmount
                ? FormatCurrency(monthlyData.monthlyTFSAmount)
                : "-"}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MonthlyTable