import React from "react"
import TableContainer from "Components/Common/Table/TableContainer"
import { GetColumns, GetTaskColumns } from "./constant"

export const GetWorkflowStatistics = ({
  statistics,
  isOutbound,
  isCreateBundle,
}: any) => {
  const columns = GetColumns(isOutbound, isCreateBundle)

  return (
    <React.Fragment>
      <TableContainer
        columns={columns || []}
        data={statistics || []}
        customPageSize={5}
        showPagination={false}
        clickable={false}
      />
    </React.Fragment>
  )
}

export const GetTaskStatistics = ({ statistics, taskName }: any) => {
  const columns = GetTaskColumns(taskName)

  return (
    <React.Fragment>
      <TableContainer
        columns={columns || []}
        data={statistics || []}
        customPageSize={5}
        showPagination={false}
        clickable={false}
      />
    </React.Fragment>
  )
}
