import React from 'react'
import TableContainer from '../../../../Components/Common/Table/TableContainer' 
import { CardBody, CardHeader } from 'reactstrap'

export const LastFilesTable = ({ files }: any) => {

  const columns = [
    {
      header: "File Name",
      accessorKey: "filename", 
      enableColumnFilter: false,
     cell: (cell:any) => {
                 const value = cell.getValue()
                 return value === null ? (
                   "-"
                 ) : (
                   value
                 )
               },
    },
    {
      header: 'Date',
      accessorKey: 'timestamp',  
      enableColumnFilter: false,
      cell: (cell:any) => {
        const value = cell.getValue()
        return value === null ? (
          "-"
        ) : (
          value
        )
      },
    },
  ]

  return (
    <>
      <CardHeader className='mt-2 -ml-3 bg-white z-30'>
        <h5 className="card-title mb-0 bg-white">Last 10 Files</h5>
      </CardHeader>
      <CardBody className="text-xs -mt-4 -ml-4">
        <div className='max-h-36 overflow-scroll z-10'>
        <TableContainer
          columns={columns || []}
          data={files || []}
          showPagination={false} 
          clickable={false}
        />
        </div>
      </CardBody>
    </>
  )
}
