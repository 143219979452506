import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  statistics: [],
  statisticsLoading: false,
  error: null,
}

const statisticsByWorkflowIdSlice = createSlice({
  name: "statisticsByWorkflowId",
  initialState,
  reducers: {
    fetchstatisticsByWorkflowIdStart: (state) => {
      state.statisticsLoading = true
    },
    fetchstatisticsByWorkflowIdSuccess: (state, action) => {
      state.statisticsLoading = false
      state.statistics = action.payload
    },
    fetchstatisticsByWorkflowIdFailure: (state, action) => {
      state.error = action.payload
      state.statisticsLoading = false
    },
  },
})

export const {
  fetchstatisticsByWorkflowIdStart,
  fetchstatisticsByWorkflowIdSuccess,
  fetchstatisticsByWorkflowIdFailure,
} = statisticsByWorkflowIdSlice.actions

export default statisticsByWorkflowIdSlice.reducer
