import { useEffect } from "react"
import { getSessionStorageData } from "helpers/Formatters/Format"
import { formatTime, formatDate } from "helpers/Formatters/DateFormatter"
import {
  STATUS_COMPLETED,
  STATUS_IN_PROGRESS,
  STATUS_PENDING,
  TASK_CREATE_BUNDLE,
  OUTBOUND_TASKS,
  OUTBOUND_ACH_WORKFLOW
} from "../constants/constants"
import { fetchWorkflows } from "slices/workflow/thunk"
import { fetchTickets } from "slices/tickets/thunk"
import {
  getTasksByWorkflowId,
  hasCompletedBundle,
  getWorkflowById
} from "./constants/constantFunctions"

export const Workflow = (
  state,
  setStartTime,
  setWorkflowStatus,
  dispatch
) => {
  useEffect(() => {
    if (state) {
      dispatch(fetchWorkflows())
      setStartTime(state?.key?.started_time)
      setWorkflowStatus(state?.key?.status)
      dispatch(fetchTickets())
    }
  }, [])
}

export const Tasks = (
  workflows,
  loading,
  state,
  tasks,
  setTasks,
  setStartTime,
  setWorkflowStatus,
  setIsOutbound,
  setTaskStatistics,
  setStats,
  dispatch,
  isOutbound
) => {
  useEffect(() => {
    if (workflows && !loading && state) {
      const targetId = state?.key?.workflow_id
      const newTasks = getTasksByWorkflowId(workflows, targetId)

      if (JSON.stringify(newTasks) !== JSON.stringify(tasks)) {
        const updatedTasks = newTasks.map((task, index) => {
          const updatedTask = { ...task, isLoading: false }

          if (
            task?.status === STATUS_PENDING &&
            index > 0 &&
            newTasks[index - 1]?.status?.toLowerCase() === STATUS_COMPLETED
          ) {
            if (isOutbound) {
              const isCompleted = OUTBOUND_TASKS.every((taskKey) =>
                tasks?.some(
                  (obj) =>
                    obj.key_name === taskKey && obj.status === STATUS_COMPLETED
                )
              )

              if (updatedTask?.key_name === TASK_CREATE_BUNDLE) {
                updatedTask.isLoading = isCompleted
              }
            } else {
              updatedTask.isLoading = true
            }
          }
          const isFailed = getSessionStorageData(`restarted_${task?.id}`)
          const isEscalated = getSessionStorageData(`escalated_${task?.id}`)

          if (task?.status.toLowerCase() === STATUS_IN_PROGRESS && isFailed) {
            sessionStorage.setItem(
              `started_${task?.id}`,
              JSON.stringify(false)
            )
            sessionStorage.setItem(
              `restarted_${task?.id}`,
              JSON.stringify(false)
            )
          }
          if (
            task?.status.toLowerCase() === STATUS_PENDING &&
            isEscalated &&
            index > 0
          ) {
            sessionStorage.setItem(
              `started_${newTasks[index - 1]?.id}`,
              JSON.stringify(false)
            )
            sessionStorage.setItem(
              `escalated_${task?.id}`,
              JSON.stringify(false)
            )
          }
          return updatedTask
        })
        setTasks(updatedTasks)
      }

      const matchingWorkflow = getWorkflowById(workflows, targetId)
      if (matchingWorkflow) {
        setStartTime(matchingWorkflow?.start_time)
        setWorkflowStatus(matchingWorkflow?.status)
        const filteredTaskResults = matchingWorkflow?.tasks
          ?.filter((task) => OUTBOUND_TASKS?.includes(task.key_name))
          ?.flatMap((task) => {
            const resultData = Array.isArray(task.result)
              ? task.result
              : [task.result]
            return {
              keyName: task.key_name,
              result: resultData,
            }
          })

        setTaskStatistics(filteredTaskResults)

        if (matchingWorkflow?.workflow_key_name === OUTBOUND_ACH_WORKFLOW) {
          setIsOutbound(true)
        }

        hasCompletedBundle(matchingWorkflow, targetId, dispatch, setStats)

        const newTasks = matchingWorkflow?.tasks
        const updatedTasks = newTasks?.map((task, index) => {
          const updatedTask = { ...task, isLoading: false }
          if (
            task?.status === STATUS_PENDING &&
            index > 0 &&
            tasks[index - 1]?.status === STATUS_COMPLETED
          ) {
            if (isOutbound) {
              const isCompleted = OUTBOUND_TASKS.every((taskKey) =>
                tasks?.some(
                  (obj) =>
                    obj.key_name === taskKey && obj.status === STATUS_COMPLETED
                )
              )
              if (updatedTask?.key_name === TASK_CREATE_BUNDLE) {
                updatedTask.isLoading = isCompleted
              }
            } else {
              updatedTask.isLoading = true
            }
          }
          return updatedTask
        })
        setTasks(updatedTasks)
      }
    }
  }, [workflows, loading, state])
}

export const Statistics = (
  statistics,
  statisticsLoading,
  isOutbound,
  setStats
) => {
  useEffect(() => {
    if (statistics && !statisticsLoading && isOutbound) {
      setStats(statistics)
    }
  }, [statistics, statisticsLoading])
}

export const UpdateTask = (state, tasks, isOutbound, setTasks) => {
  useEffect(() => {
    if (state) {
      if (state?.key?.tasks) {
        const newTasks = state?.key?.tasks
        const updatedTasks = newTasks.map((task, index) => {
          const updatedTask = { ...task, isLoading: false }

          if (
            task?.status === STATUS_PENDING &&
            index > 0 &&
            tasks[index - 1]?.status === STATUS_COMPLETED
          ) {
            if (isOutbound) {
              const isCompleted = OUTBOUND_TASKS.every((taskKey) =>
                tasks?.some(
                  (obj) =>
                    obj.key_name === taskKey && obj.status === STATUS_COMPLETED
                )
              )
              if (updatedTask?.key_name === TASK_CREATE_BUNDLE) {
                updatedTask.isLoading = isCompleted
              }
            } else {
              updatedTask.isLoading = true
            }
          }
          return updatedTask
        })

        setTasks(updatedTasks)
      }
    }
  }, [state])
}

export const Tickets = (
  tickets,
  loadingTickets,
  state,
  setTicketsData
) => {
  useEffect(() => {
    if (tickets && !loadingTickets) {
      const targetId = state?.key?.workflow_id
      const matchingTickets = ((tickets, targetId) => {
        return tickets?.filter((ticket) => ticket.workflow_id === targetId)
      })(tickets, targetId)

      const result = matchingTickets?.map((item) => {
        return {
          ticketID: item?.ticket_id,
          workflowName: item?.workflow_name,
          workflowID: item?.workflow_id,
          taskName: item?.task_name,
          subject: item?.error?.subject,
          createAt:
            item?.created_at !== ""
              ? formatDate(item?.created_at) +
              " ," +
              formatTime(item?.created_at)
              : "",
          status: item?.ticket_status,
          priority: item?.priority,
          url: item?.ticket_url,
        }
      })
      setTicketsData(result)
    }
  }, [loadingTickets, tickets, state])
}