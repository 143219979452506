import BreadCrumb from "Components/Common/BreadCrumb"
import React, { useEffect, useRef, useState } from "react"
import DatePicker from "react-datepicker"
import { useLocation, useNavigate } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import { DateTime } from "ts-luxon"
import classnames from "classnames"
import {
  API_SUCCESS_RESPONSE,
  DEBIT_CARD,
  INBOUND_ACH,
  MAX_DATE,
  MONEY_GRAM,
  TAB_IDS,
  WAGE_DEDUCTION,
  ZERO_OUT_INBOUND_REPORT,
  ZERO_OUT_INBOUND_REPORT_PAGE_SIZE,
  ZERO_OUT_INBOUND_REPORT_TRANSACTIONS,
  ZERO_OUT_SUMMARY_REPORT,
} from "Components/constants/constants"
import { determineBaseUrlAndPerformApiCall } from "slices/interceptor/interceptor"
import { toast } from "react-toastify"
import { TransactionDetails } from "./TransactionTypes"
import { formatDate } from "helpers/Formatters/DateFormatter"
import Button from "Components/Common/Button"
import { useDispatch, useSelector } from "react-redux"
import { addDates } from "slices/Reports/InboundSummary/reducer"

const InboundDaySummary = () => {
  document.title = "Inbound Day Summary | TFS Processing App"
  const location = useLocation()
  const state = location?.state 

  const [loadingData, setLoadingData] = useState(true)
  const [loadingTable, setLoadingTable] = useState(true)
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const datePickerRef = useRef<any>(null)
  const [details, setDetails] = useState<any>({})
  const [transactionDetails, setTransactionDetails] = useState<any>({})
  const [activeTab, setActiveTab] = useState<any>(INBOUND_ACH)
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1)
  const [sort, setSort] = useState<any>({})
  const [search, setSearch] = useState<any>([])
  const [pageLimit, setPageLimit] = useState<any>({})
  const [includedDates, setIncludedDates] = useState<any>(null)
  const [isLeftArrowDisabled, setIsLeftArrowDisabled] = useState<boolean>(true)
  const [dataToBePassed, setDataToBePassed] = useState<any>(state)
  const [isRightArrowDisabled, setIsRightArrowDisabled]= useState<boolean>(true)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Enabled Dates
  const { inboundDates } = useSelector((state: any) => state.InboundDates)

    useEffect(() => {
      if (inboundDates) {
        // Convert inboundDates (array of strings) to Date objects or ISO strings
        const dates = inboundDates.map((dateString: string) => {
          // Parse the date string as EDT (America/New_York)
          const edtDate = DateTime.fromISO(dateString, { zone: 'America/New_York' })
  
          // Convert to a JavaScript Date object (or ISO string, depending on your date picker)
          return edtDate.toJSDate() // Use .toISO() if you need an ISO string
        })
  
        setIncludedDates(dates)
      }
    }, [inboundDates])
  
  useEffect(() => {
    const getSummary = async () => {
      const year = state?.asOfEndOfDay.getFullYear()
      const month = (state?.asOfEndOfDay.getMonth() + 1)
        .toString()
        .padStart(2, "0") // Months are zero-indexed
      const day = state?.asOfEndOfDay.getDate().toString().padStart(2, "0")
      const formattedSelectedDate = `${year}-${month}-${day}`

      try {
        await determineBaseUrlAndPerformApiCall(
          `${ZERO_OUT_SUMMARY_REPORT}${formattedSelectedDate}`,
          "GET"
        )
          .then((response: any) => {
            if (response?.message === API_SUCCESS_RESPONSE) {
              const inboundDates = response?.data?.transactions?.map(
                (item: any) => item?.inbound_date
              )
              dispatch(addDates(inboundDates))
            }
          })
          .catch((errorResp) => {
            toast.error(errorResp, { position: "top-center" })
            console.error(errorResp)
          })
      } catch (error: any) {
        toast.error(error?.message, { position: "top-center" })
        console.error(error)
      }
    }

    getSummary()
  }, [])

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  useEffect(() => {
    if (state) {
      const formattedDate = DateTime.fromFormat(
        state?.inboundDate,
        "MM-dd-yyyy"
      ).toJSDate()
      setSelectedDate(formattedDate)
      if (state?.productType) {
        setActiveTab(state.productType)
      } else {
        setActiveTab(INBOUND_ACH)
      }    }
  }, [])

  useEffect(() => {
    if (state) {
      const formattedDate = DateTime.fromFormat(
        state?.inboundDate,
        "MM-dd-yyyy"
      ).toJSDate()
      setSelectedDate(formattedDate)
      if (state?.productType) {
        setActiveTab(state.productType)
      } else {
        setActiveTab(INBOUND_ACH)
      }
    }
  }, [state])

  useEffect(() => {
    if(selectedDate){
      const dataToPass = dataToBePassed
      dataToPass.inboundDate = DateTime.fromJSDate(selectedDate).toFormat("MM-dd-yyyy")
      setDataToBePassed(dataToPass)  
    }
  }, [selectedDate])

  useEffect(() => {
    if(activeTab){
      const dataToPass = dataToBePassed
      dataToPass.productType = activeTab
      setDataToBePassed(dataToPass)  
    }
  }, [activeTab])

  useEffect(() => {
    if (selectedDate && includedDates.length > 0) {
      const currentDate = new Date(selectedDate)
      currentDate.setHours(0, 0, 0, 0) // Normalize the time part
  
      // Check if there are valid dates before/after the current date
      const hasPreviousDate = includedDates.some((date: any) => {
        const normalizedDate = new Date(date)
        normalizedDate.setHours(0, 0, 0, 0)
        return normalizedDate < currentDate
      })
  
      const hasNextDate = includedDates.some((date: any) => {
        const normalizedDate = new Date(date)
        normalizedDate.setHours(0, 0, 0, 0)
        return normalizedDate > currentDate
      })
  
      // Update arrow states
      setIsLeftArrowDisabled(!hasPreviousDate)
      setIsRightArrowDisabled(!hasNextDate)
    } else {
      // If no date is selected or includedDates is empty, disable both arrows
      setIsLeftArrowDisabled(true)
      setIsRightArrowDisabled(true)
    }
  }, [selectedDate, includedDates])

  const handlePreviousDate = () => {
    setSelectedDate((prevDate) => {
      if (!prevDate) return prevDate // If no date is selected, do nothing
  
      const currentDate = new Date(prevDate)
      currentDate.setHours(0, 0, 0, 0) // Normalize the time part to avoid timezone issues
  
      // Find the previous valid date from the list that is smaller than the current date
      const prevValidDate = includedDates
        .filter((date:any) => {
          const normalizedDate = new Date(date)
          normalizedDate.setHours(0, 0, 0, 0)
          return normalizedDate < currentDate
        })
        .sort((a: any, b: any) => b.getTime() - a.getTime()) // Sort dates in descending order
        .shift() // Get the first element (previous valid date)
  
      // Return the previous valid date or fallback to the current date if no valid date is found
      return prevValidDate || prevDate
    })
  }

  const handleNextDate = () => {
    setSelectedDate((prevDate) => {
      if (!prevDate) return prevDate // If no date is selected, do nothing
  
      const currentDate = new Date(prevDate)
      currentDate.setHours(0, 0, 0, 0) // Normalize the time part to avoid timezone issues
  
      // Find the next valid date from the list that is greater than the current date
      const nextValidDate = includedDates
        ?.filter((date: any) => {
          const normalizedDate = new Date(date)
          normalizedDate.setHours(0, 0, 0, 0)
          return normalizedDate > currentDate
        })
        .sort((a: any, b: any) => a.getTime() - b.getTime()) // Sort dates in ascending order
        .shift() // Get the first element (next valid date)
  
      // Return the next valid date or fallback to the current date if no valid date is found
      return nextValidDate || prevDate
    })
  }

  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true)
    }
  }

  useEffect(() => {
    if (selectedDate) {
      setLoadingData(true)
      const year = selectedDate.getFullYear()
      const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0") // Months are zero-indexed
      const day = selectedDate.getDate().toString().padStart(2, "0")
      const formattedSelectedDate = `${year}-${month}-${day}`

      const getSummary = async () => {
        try {
          await determineBaseUrlAndPerformApiCall(
            `${ZERO_OUT_INBOUND_REPORT}inbound_date=${formattedSelectedDate}&payment_type=${activeTab}&end_date=${formatDate(
              state?.asOfEndOfDay, "YYYY-MM-DD")}`,
            "GET"
          )
            .then((response: any) => {
              if (response?.message !== API_SUCCESS_RESPONSE) {
                setLoadingData(false)
                // toast.info(response?.message, { position: "top-center" })
                setDetails({})
              } else {
                setDetails(response?.data)
                setLoadingData(false)
              }
            })
            .catch((errorResp) => {
              setLoadingData(false)
              toast.error(errorResp, { position: "top-center" })
              console.error(errorResp)
            })
        } catch (error: any) {
          setLoadingData(false)
          toast.error(error?.message, { position: "top-center" })
          console.error(error)
        }
      }
      getSummary()
    }
  }, [selectedDate, activeTab, currentPageNumber])

  useEffect(() => {
    if (selectedDate) {
      // setLoading(true)
      setLoadingTable(true)
      const year = selectedDate.getFullYear()
      const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0") // Months are zero-indexed
      const day = selectedDate.getDate().toString().padStart(2, "0")
      const formattedSelectedDate = `${year}-${month}-${day}`

      const endDatePart = new Date(state?.asOfEndOfDay)
        .toISOString()
        .split("T")[0]

      const getSummaryTransactions = async () => {
        const sortFilter: any =
          sort && sort?.fieldName
            ? { fieldName: sort?.fieldName, value: sort?.value } // Include the sort object if sorting is applied
            : {}

        const body = JSON.stringify({
          sort: sortFilter,
          search,
          pagination: {
            page_size: ZERO_OUT_INBOUND_REPORT_PAGE_SIZE,
            page_no: currentPageNumber,
          },
          inbound_date: formattedSelectedDate,
          end_date: endDatePart,
          payment_type: activeTab,
        })

        try {
          await determineBaseUrlAndPerformApiCall(
            `${ZERO_OUT_INBOUND_REPORT_TRANSACTIONS}`,
            "POST",
            body
          )
            .then((response: any) => {
              if (response?.message !== API_SUCCESS_RESPONSE) {
                setTransactionDetails([])
                setLoadingTable(false)
                setPageLimit({
                  total_items: 0,
                  total_pages: 0,
                })
                // toast.info(response?.message, { position: "top-center" })
              } else {
                setTransactionDetails(response?.data)
                setPageLimit(response?.data?.pagination)
                setLoadingTable(false)
              }
            })
            .catch((errorResp) => {
              setLoadingTable(false)
              toast.error(errorResp, { position: "top-center" })
              console.error(errorResp)
            })
        } catch (error: any) {
          setLoadingTable(false)
          toast.error(error?.message, { position: "top-center" })
          console.error(error)
        }
      }
      getSummaryTransactions()
    }
  }, [selectedDate, activeTab, currentPageNumber, sort, search])

  useEffect(() => {
    if (selectedDate && activeTab) {
      setSort({})
      setSearch([])
      setCurrentPageNumber(1)
    }
  }, [selectedDate, activeTab])

  useEffect(() => {
    if (sort || search) {
      setCurrentPageNumber(1)
    }
  }, [sort, search])

  const renderTabPane = (tabId: any) => (
    <TabPane key={tabId} tabId={tabId}>
      <TransactionDetails
        transDetails={details}
        listDetails={transactionDetails}
        type={activeTab}
        loadingData={loadingData}
        loadingTable={loadingTable}
        sort={sort}
        setSort={setSort}
        search={search}
        setSearch={setSearch}
        pageLimit={pageLimit}
        currentPageNumber={currentPageNumber}
        setCurrentPageNumber={setCurrentPageNumber}
        navigateData={dataToBePassed}
      />
    </TabPane>
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Inbound Day Summary"
            pageTitle="Report"
            action="/inbound-day-summary"
            subTitle="Zero Out Summary Report"
            subAction="/zero-out-summary-report"
          />
          {/* Date Picker */}
          <div className="relative flex flex-col md:flex-row items-center justify-between md:gap-0 mb-4">
            <div className="self-start md:self-center">
              <Button
                onClick={() => {
                  navigate("/zero-out-summary-report", {
                    state: { key: { outboundDate: state?.asOfEndOfDay } },
                  })
                }}
                label="Back"
                styleType="primary"
              />
            </div>
            <div className="flex flex-col items-center w-full md:w-auto">
              <p className="text-gray-700 font-bold text-base">
                Inbound Day
              </p>
              <div className="flex items-center gap-x-4">
                {/* Left Arrow */}
                <div
                  onClick={
                    !isLeftArrowDisabled ? handlePreviousDate : undefined
                  }
                  className={`rounded bg-[#31506A] cursor-pointer hover:bg-[#2b435a] px-2 ${
                    isLeftArrowDisabled ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                >
                  <i className="ri-arrow-left-line text-white text-lg" />
                </div>
                {/* Date Picker */}
                <div className="relative inline-flex items-center w-auto">
                  <DatePicker
                    className="border-1 border-black-500 rounded-md font-bold placeholder:text-black  h-8 pl-4 text-sm w-36"
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="MM-dd-yyyy"
                    placeholderText={MAX_DATE.toString()}
                    ref={datePickerRef}
                    includeDates={includedDates}
                  />
                  <i
                    className="ri-calendar-2-line absolute right-5 bottom-0.5 text-gray-500 text-lg cursor-pointer"
                    onClick={handleIconClick}
                  />
                </div>
                {/* Right Arrow */}
                <div
                  onClick={!isRightArrowDisabled ? handleNextDate : undefined}
                  className={`rounded bg-[#31506A] cursor-pointer hover:bg-[#2b435a] px-2 ${
                    isRightArrowDisabled ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                >
                  <i className="ri-arrow-right-line text-white text-lg" />
                </div>
              </div>
            </div>
            <div className="md:flex flex-col gap-1 text-center md:text-right md:block mt-3">
              <p className="font-semibold text-gray-700 m-0 p-0">
                As of End of Day:
              </p>
              <p className="font-bold m-0 p-0 text-base">
                {formatDate(state?.asOfEndOfDay)}
              </p>
            </div>
          </div>

          <Row className="relative">
            <Col>
              <Card>
                <CardBody>
                  {/* Action Tabs */}
                  <Nav fill tabs className="nav-tabs-custom2 nav-success mb-3">
                    <NavItem>
                      <NavLink
                        className={`${classnames({
                          active: activeTab === INBOUND_ACH,
                        })} cursor-pointer`}
                        onClick={() => toggle(INBOUND_ACH)}
                      >
                        <p className="p-0 m-0 text-base font-bold">ACH</p>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={`${classnames({
                          active: activeTab === MONEY_GRAM,
                        })} cursor-pointer`}
                        onClick={() => toggle(MONEY_GRAM)}
                      >
                        <p className="p-0 m-0 text-base font-bold">
                          Money Gram
                        </p>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={`${classnames({
                          active: activeTab === WAGE_DEDUCTION,
                        })} cursor-pointer`}
                        onClick={() => toggle(WAGE_DEDUCTION)}
                      >
                        <p className="p-0 m-0 text-base font-bold">
                          Wage Deduction
                        </p>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={`${classnames({
                          active: activeTab === DEBIT_CARD,
                        })} cursor-pointer`}
                        onClick={() => toggle(DEBIT_CARD)} // Fixed index for consistency
                      >
                        <p className="p-0 m-0 text-base font-bold">
                          Debit Card
                        </p>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  {/* Action Tab Items */}
                  <TabContent activeTab={activeTab}>
                    {TAB_IDS.map(renderTabPane)}
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default InboundDaySummary