import React, { useState, useEffect, useRef } from "react"
import { Container } from "reactstrap"
import { formatDate } from "../../../helpers/Formatters/DateFormatter"
import BreadCrumb from "../../../Components/Common/BreadCrumb"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { toast, ToastContainer } from "react-toastify"
import { DateTime } from "ts-luxon"
import { useLocation } from "react-router-dom"
import { calculateCustomBusinessDaysBefore } from "../Helpers/helper"
import TableContainer from "./TableContainer/index"
import {
  MAX_DATE,
} from "Components/constants/constants"
import NoDataFound from "../../../assets/images/no-data.avif"
import { fetchHolidays } from "slices/Holidays/thunk"
import { useDispatch, useSelector } from "react-redux"
import { getFeeCalculationData } from "slices/Reports/FeeCalculation/thunk"
const ZeroOut: React.FC = () => {
  document.title = "Fees Calculation | TFS Processing App"

  const location = useLocation()
  const { holidays } = useSelector((state: any) => state.Holidays)
  const [selectedDate, setSelectedDate] = useState<DateTime | null>(null)
  const [loading, setLoading] = useState(true)
  const [maxDate, setMaxDate] = useState<any>(null)
  const [disabledDates, setDisabledDates] = useState<any>(null)
  const datePickerRef = useRef<any>(null)
  const [tableData, setTableData] = useState<any[]>([])
  const [weekDates, setWeekDates] = useState<string[]>([])
  const [currentDate] = useState<DateTime>(MAX_DATE)
  const [monthlyData, setMonthlyData] = useState<{
    monthlyFeesCollected: any
    monthlyEsquireCosts: any
    monthlyTFSAmount: any
    start_date: any
    end_date: any
  } | null>(null)
  const dispatch = useDispatch<any>()

  useEffect(() => {
    if (holidays) {
      const dates = holidays?.map((item: any) =>
        DateTime.fromISO(item)
          .setZone("America/New_York")
          .startOf("day")
          .toJSDate()
      )
      setDisabledDates(dates)
    }
  }, [holidays])

  useEffect(() => {
    dispatch(fetchHolidays())
    if (location?.state?.key?.outboundDate) {
      const parsedDate = location?.state?.key?.outboundDate
      setSelectedDate(parsedDate)
    } else if (holidays) {
      const dates = holidays?.map((item: any) =>
        DateTime.fromISO(item)
          .setZone("America/New_York")
          .startOf("day")
          .toJSDate()
      )
      setDisabledDates(dates)
      const result = calculateCustomBusinessDaysBefore(MAX_DATE, dates, 0)
      setSelectedDate(result.startOf("day"))
    }
  }, [])

  const isDisabled = (date: Date) => {
    const dateTime = DateTime.fromJSDate(date).startOf("day")
    const isWeekend = dateTime.weekday === 6 || dateTime.weekday === 7
    const isHoliday = holidays.some((holiday: any) =>
      DateTime.fromISO(holiday.toString()).hasSame(dateTime, "day")
    )
    return (!isWeekend && !isHoliday) || dateTime > currentDate
  }

  const handleDateChange = (direction: number) => {
    setSelectedDate((prevDate) => {
      let date = prevDate ? prevDate : currentDate
      if (direction > 0) {
        // Disable the right arrow if the current date is already MAX_DATE
        if (date.hasSame(MAX_DATE, "day")) {
          return date 
        }
        if (date.weekday === 3) {
          const nextWednesday = date.plus({ days: 7 })
          if (nextWednesday <= MAX_DATE) {
            date = nextWednesday
          } else {
            date = MAX_DATE // Set to MAX_DATE if next Wednesday is in the future
          }
        } else {
          date = date.plus({ days: 7 })
          while (date.weekday !== 3) {
            date = date.plus({ days: 1 })
          }
          if (date > MAX_DATE) {
            date = MAX_DATE
          }
        }
      } else {
        if (date.weekday === 3) {
          date = date.minus({ days: 7 })
        } else {
          while (date.weekday !== 3) {
            date = date.minus({ days: 1 })
          }
        }
      }
      if (date.hasSame(MAX_DATE, "day")) {
        date = calculateCustomBusinessDaysBefore(date, disabledDates, 0)
      }
      return date
    })
  }
  const isRightArrowDisabled = selectedDate?.hasSame(MAX_DATE, "day") || false

  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true)
    }
  }
  useEffect(() => {
    if (disabledDates) {
      const result = calculateCustomBusinessDaysBefore(
        MAX_DATE,
        disabledDates,
        0
      )
      setMaxDate(result)
    }
  }, [disabledDates])

  useEffect(() => {
    if (selectedDate) {
      setLoading(true)
      const inputDate = selectedDate
      const startOfWeek =
        inputDate.weekday >= 4
          ? inputDate.minus({ days: inputDate.weekday - 4 })
          : inputDate.minus({ days: inputDate.weekday + 3 })
      const dates: string[] = []
      let currentDate = startOfWeek
      while (dates.length < 5) {
        const dayOfWeek = currentDate.weekday
        if (dayOfWeek !== 6 && dayOfWeek !== 7) {
          dates.push(currentDate.toFormat("yyyy-MM-dd"))
        }
        if (
          currentDate.toFormat("yyyy-MM-dd") ===
          MAX_DATE.minus({ days: 1 }).toFormat("yyyy-MM-dd")
        ) {
          break
        }
        currentDate = currentDate.plus({ days: 1 })
      }
      setWeekDates(dates)

      const fetchData = async () => {
        const response = await getFeeCalculationData(dates)
        if (response.success) {
          setTableData(response.data?.daily || [])
          setMonthlyData(response.data?.monthly || null)
        } else {
          setTableData([])
          setMonthlyData(null)
          if (response.error) {
            toast.error(response.error, { position: "top-center" })
            console.error(response.error)
          }
        }
        setLoading(false)
      }
      fetchData()
    }
  }, [selectedDate])

  return (
    <React.Fragment>
      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="page-content" id="processingContent">
          <ToastContainer />
          <Container fluid>
            <BreadCrumb
              title="Fees Calculation"
              pageTitle="Report"
              action="/fees_calculation"
            />
            <div className="flex flex-col items-center justify-center gap-2">
              <div className="flex items-center gap-x-4">
                <div
                  onClick={() => handleDateChange(-1)}
                  className="rounded bg-[#31506A] cursor-pointer hover:bg-[#2b435a] px-2"
                >
                  <i className="ri-arrow-left-line text-white text-lg" />
                </div>
                <div className="relative inline-flex items-center w-auto justify-centre">
                  <DatePicker
                    className="border-1 border-black-500 rounded-md font-bold placeholder:text-black h-8 pl-4 text-sm w-36"
                    selected={selectedDate ? selectedDate?.toJSDate() : null}
                    onChange={(date) => {
                      const newDate = date ? DateTime.fromJSDate(date) : null
                      if (newDate && newDate.hasSame(DateTime.now(), "day")) {
                        setSelectedDate(
                          calculateCustomBusinessDaysBefore(
                            newDate,
                            disabledDates,
                            0
                          )
                        )
                      } else {
                        setSelectedDate(newDate)
                      }
                    }}
                    dateFormat="MM-dd-yyyy"
                    placeholderText={maxDate.toFormat("MM-dd-yyyy")}
                    ref={datePickerRef}
                    excludeDates={disabledDates}
                    filterDate={(date) => isDisabled(date)}
                    maxDate={calculateCustomBusinessDaysBefore(
                      MAX_DATE,
                      disabledDates,
                      0
                    )
                      .startOf("day")
                      .toJSDate()}
                  />
                  <i
                    className="ri-calendar-2-line absolute right-5 bottom-1 text-gray-500 text-lg cursor-pointer"
                    onClick={handleIconClick}
                  />
                </div>
                <div
                  onClick={() => !isRightArrowDisabled && handleDateChange(1)}
                  className={`rounded bg-[#31506A] cursor-pointer hover:bg-[#2b435a] px-2 ${isRightArrowDisabled ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  aria-disabled={isRightArrowDisabled}
                >
                  <i className="ri-arrow-right-line text-white text-lg" />
                </div>
              </div>
            </div>
            <>
              <TableContainer
                tableData={tableData.length > 0 ? tableData : []}
                monthlyData={
                  monthlyData || {
                    monthlyFeesCollected: 0,
                    monthlyEsquireCosts: 0,
                    monthlyTFSAmount: 0,
                    start_date: "",
                    end_date: "",
                  }
                }
              />
              {tableData.length === 0 && (
                <div className="overflow-x-auto py-1 bg-gray-100 py-3 min-w-full">
                  {selectedDate && (
                    <div className="min-w-full">
                      <table className="border-collapse min-w-full border-1 border-[#B6B6B6]">
                        <tbody>
                          <tr>
                            <td className="p-2 border-r border-[#B6B6B6] bg-white min-w-[175px]"></td>
                            {weekDates.map((date, index) => (
                              <td
                                key={index}
                                className="p-2 border-r border-[#B6B6B6] bg-white font-bold min-w-[175px] text-center"
                              >
                                <div className="flex justify-center items-center min-w-full">
                                  {DateTime.fromISO(date).toFormat("cccc")}
                                </div>
                              </td>
                            ))}
                          </tr>
                          <tr>
                            <td className="p-2 border-r border-b bg-[#F4F3F2] font-bold min-w-[175px]">
                              <div className="flex items-center min-w-full">
                                <span>Settled Date</span>
                              </div>
                            </td>
                            {weekDates.map((date, index) => (
                              <td
                                key={index}
                                className="p-2 border-r border-b border-[#B6B6B6] bg-[#F4F3F2] text-[#000000] min-w-[175px]"
                              >
                                <div className="flex justify-center items-center min-w-full">
                                  <span>{formatDate(date)}</span>
                                </div>
                              </td>
                            ))}
                          </tr>
                          <tr>
                            <td
                              colSpan={weekDates.length + 1}
                              className="p-2 border min-w-full"
                            >
                              <div className="flex justify-center items-center min-w-full bg-white">
                                <img
                                  src={NoDataFound}
                                  alt="No Records Found"
                                  className="w-72 h-72"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
            </>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default ZeroOut