import React, { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast, ToastContainer } from "react-toastify"
import { updateWorkflows } from "slices/workflow/reducer"
import { updateTickets } from "slices/tickets/reducer"
import { updateActions } from "slices/Actions/reducer"
import { updateNotifications } from "slices/Notification/reducer"
import { loginSuccess } from "slices/auth/login/reducer"
import { SSE_PATH } from "Components/constants/constants"
import "./assets/scss/themes.scss"
import Route from "./Routes"
import { fetchPermissions } from "slices/Permissions/thunk"

function App() {
  const dispatch: any = useDispatch()
  const { isUserLoggedIn } = useSelector((state: any) => state.Login)
  const { permissions } = useSelector((state: any) => state.Permissions)
  const isConnected = useRef(false)

  useEffect(() => {
    const token = sessionStorage.getItem("authToken")
    if (token) {
      dispatch(loginSuccess())
    }
  }, [dispatch])

  useEffect(() => {
    if (isUserLoggedIn) {
      dispatch(fetchPermissions())
    }
  }, [isUserLoggedIn])

  useEffect(() => {
    if (!isUserLoggedIn) return

    const workflowPermission = permissions?.resource_name?.find(
      (resource: any) => resource.resource_name === "workflow"
    )

    if (!workflowPermission) return

    const token = sessionStorage.getItem("authToken")
    const username = sessionStorage.getItem("username") || ""

    if (!token || !username) return

    if (isConnected.current) return

    const abortController = new AbortController()

    const connectToSSE = async () => {
      try {
        const response = await fetch(
          `${window.env.REACT_APP_BASE_URL}${SSE_PATH}${username}`,
          {
            headers: {
              "Cache-Control": "no-cache",
              Accept: "text/event-stream",
              Authorization: `Bearer ${token}`,
            },
            signal: abortController.signal,
          }
        )

        if (!response.ok) {
          throw new Error(`SSE connection failed: ${response.status}`)
        }

        const reader = response.body?.getReader()
        if (!reader) {
          throw new Error("Failed to get reader from response body")
        }

        isConnected.current = true
        const decoder = new TextDecoder()
        let buffer = ""

        while (true) {
          const { value, done } = await reader.read()
          if (done) break

          buffer += decoder.decode(value, { stream: true })
          let lineEndIndex

          while ((lineEndIndex = buffer.indexOf("\n")) >= 0) {
            const line = buffer.slice(0, lineEndIndex).trim()
            buffer = buffer.slice(lineEndIndex + 1)

            if (line.startsWith("data: ")) {
              const message = line.substring("data: ".length).trim()

              try {
                const parsedData = JSON.parse(message)

                if (parsedData.workflow_status_updates) {
                  dispatch(updateWorkflows(parsedData.workflow_status_updates))
                }
                if (parsedData.ticket_updates) {
                  dispatch(updateTickets(parsedData.ticket_updates))
                }
                if (parsedData.notification_updates) {
                  dispatch(updateNotifications(parsedData.notification_updates))
                }
                if (parsedData.manual_task_updates) {
                  dispatch(updateActions(parsedData.manual_task_updates))
                }
              } catch (error) {
                console.error("Error parsing SSE data:", error)
                toast.error("Error parsing SSE data. Please contact support.", {
                  position: "top-center",
                })
              }
            }
          }
        }
      } catch (error: any) {
        if (error?.name !== "AbortError") {
          console.error("SSE connection error:", error)
          toast.error("SSE connection error. Please contact support.", {
            position: "top-center",
          })
        }
      } finally {
        isConnected.current = false
      }
    }

    connectToSSE()

    return () => {
      abortController.abort()
      isConnected.current = false
    }
  }, [isUserLoggedIn, dispatch, permissions])

  return (
    <React.Fragment>
      <ToastContainer />
      <Route />
    </React.Fragment>
  )
}

export default App
