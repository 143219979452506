import {
  PERMISSIONS_FOR_ROLE,
  API_SUCCESS_RESPONSE,
} from "Components/constants/constants"
import { determineBaseUrlAndPerformApiCall } from "slices/interceptor/interceptor"
import { fetchPermissionsSuccess, fetchPermissionsFailure } from "./reducer"

export const fetchPermissions = () => async (dispatch) => {
  try {
    const response = await determineBaseUrlAndPerformApiCall(
      PERMISSIONS_FOR_ROLE,
      "GET"
    )
      dispatch(fetchPermissionsSuccess(response))
  } catch (e) {
    console.error(e)
    dispatch(fetchPermissionsFailure({}))
  }
}
