// src/api/feesCalculationApi.ts
import { determineBaseUrlAndPerformApiCall } from "slices/interceptor/interceptor"
import { API_SUCCESS_RESPONSE, FEES_CALCULATION } from "Components/constants/constants"

export const getFeeCalculationData = async (dates) => {
    try {
        const response = await determineBaseUrlAndPerformApiCall(
            FEES_CALCULATION.replace("${start_date}", dates[0]).replace(
                "${end_date}",
                dates[dates.length - 1]
            ),
            "GET"
        )

        return {
            success: response?.message === API_SUCCESS_RESPONSE,
            data: response?.data || null,
            error: response?.message !== API_SUCCESS_RESPONSE ? response?.message : null
        }
    } catch (error) {
        return {
            success: false,
            data: null,
            error: error?.message
        }
    }
}