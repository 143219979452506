// components/WeeklyTable/WeeklyTable.tsx
import React from "react"
import { FormatCurrency } from "helpers/Formatters/Format"

interface WeeklyTableProps {
  tableData: any[]
  title: any
  dataKey: any
  rowtitle: any
  colorClass: any
}

const WeeklyTable: React.FC<WeeklyTableProps> = ({
  tableData,
  title,
  dataKey,
  rowtitle,
  colorClass,
}) => {
  const calculateWeeklySum = (dataKey: any) => {
    return FormatCurrency(
      parseFloat(
        tableData
          .reduce((sum, item: any) => {
            const value = item[dataKey]
            const numericValue = value === "" ? 0 : Number(value)
            return sum + numericValue
          }, 0)
          .toFixed(2)
      )
    )
  }

  return (
    <table className="table-nowrap min-w-full border-collapse border-1 border-[#B6B6B6] rounded-lg">
      <thead>
        <tr className="bg-white">
          <th className="p-2 text-sm text-[#495057] min-w-[175px]">{title}</th>
          <td
            className="p-1 bg-white min-w-[150px]"
            colSpan={tableData.length}
          ></td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            className={`p-2 border-r  ${
              colorClass === "bg-[#C8C8C8]" ? "border-white" : ""
            } ${colorClass} font-bold min-w-[175px]`}
          >
            {rowtitle}
          </td>
          {tableData.map((item: any, index) => (
            <td
              key={index}
              className={`p-2 border-l text-right ${
                colorClass === "bg-[#C8C8C8]" ? "border-white " : ""
              } ${colorClass} text-[#000000] min-w-[175px]`}
            >
              <div className="flex justify-between items-center min-w-[175px]">
                <span className="mr-1">$</span>
                <span>
                  {item[dataKey] ? FormatCurrency(item[dataKey]) : "-"}
                </span>
              </div>
            </td>
          ))}
        </tr>
        <tr>
          <td className="p-2 bg-white font-bold min-w-[175px] border-r ">
            Weekly Sum
          </td>
          {tableData.map((_, index) => (
            <td
              key={index}
              className={`p-2 text-right bg-white text-[#000000] min-w-[175px] ${
                index === tableData.length - 1
                  ? "text-right"
                  : "empty-cell"
              }`}
            >
              {index === tableData.length - 1 ? (
                <span className="float-left">$</span>
              ) : (
                ""
              )}
              {index === tableData.length - 1 && (
                <span className="float-right">
                  {calculateWeeklySum(dataKey)
                    ? calculateWeeklySum(dataKey)
                    : "-"}
                </span>
              )}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  )
}

export default WeeklyTable