import { GET_WORKFLOWS } from "Components/constants/constants"
import { determineBaseUrlAndPerformApiCall } from "slices/interceptor/interceptor"
import {
  fetchWorkflowsStart,
  fetchWorkflowsSuccess,
  fetchWorkflowsFailure,
} from "./reducer"

export const fetchWorkflows = () => async (dispatch) => {
  // Dispatch fetchWorkflowsStart to set loading to true
  dispatch(fetchWorkflowsStart())

  try {
    const response = await determineBaseUrlAndPerformApiCall(
      `${GET_WORKFLOWS}`,
      "GET"
    )

    if (response.error && response.error.code === "NotFound") {
      // If no workflows are found, dispatch success with an empty array
      dispatch(fetchWorkflowsSuccess([]))
    } else {
      // Otherwise, dispatch success with the fetched workflows
      dispatch(fetchWorkflowsSuccess(response))
    }
  } catch (error) {
    // Dispatch failure if there's an error
    dispatch(fetchWorkflowsFailure(error.message))
  }
}
