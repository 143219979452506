import React, { Fragment, useEffect, useState } from "react"
import { Table } from "reactstrap"
import { useNavigate } from "react-router-dom"
import defaultArrow from "./../../../assets/images/svg/updownarrow.svg"
import upArrow from "./../../../assets/images/uparrow.png"
import downArrow from "./../../../assets/images/downarrow.png"

import {
  ColumnFiltersState,
  FilterFn,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table"
import Select from "react-select"

import { rankItem } from "@tanstack/match-sorter-utils"
import Button from "../Button"
import {
  CustomMultiValue,
  CustomOption,
  DropDownValue,
  Filter,
  handleSortClick,
} from "./Components"
import { BasicPagination, PaginatedIndex } from "./Pagination"

interface TableContainerProps {
  columns?: any
  data?: any
  isGlobalFilter?: any
  handleTaskClick?: any
  customPageSize?: any
  tableClass?: any
  theadClass?: any
  trClass?: any
  iscustomPageSize?: any
  thClass?: any
  divClass?: any
  SearchPlaceholder?: any
  handleLeadClick?: any
  handleCompanyClick?: any
  handleContactClick?: any
  handleTicketClick?: any
  isBordered?: any
  showPagination?: any
  clickable?: boolean
  navigateTo?: any
  isHistoryNavigate?: boolean
  pageLimit?: any
  currentPageNumber?: number
  setCurrentPageNumber?: any
  onSort?: any
  onSearch?: any
  sort?: any
  search?: any
  loading?: any
  navigateData?: any
  resetFilter?: any
  options?: any
}

const TableContainer = ({
  columns,
  data,
  customPageSize,
  theadClass,
  trClass,
  thClass,
  divClass,
  showPagination,
  clickable,
  navigateTo,
  isHistoryNavigate = true,
  pageLimit,
  currentPageNumber,
  setCurrentPageNumber,
  onSort,
  sort,
  onSearch,
  search,
  loading,
  navigateData,
  resetFilter = false,
  options,
}: TableContainerProps) => {
  const history = useNavigate()
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState("")
  const [selectedOptions, setSelectedOptions] = useState<any>([])

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value)
    addMeta({
      itemRank,
    })
    return itemRank.passed
  }

  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const {
    getHeaderGroups,
    getRowModel,
    getCanPreviousPage,
    getCanNextPage,
    getPageOptions,
    setPageIndex,
    nextPage,
    previousPage,
    setPageSize,
    getState,
  } = table

  useEffect(() => {
    if (Number(customPageSize)) {
      setPageSize(Number(customPageSize))
    }
  }, [customPageSize, setPageSize])

  useEffect(() => {
    if (search){
        // Extract the fieldNames from the comparison array
        const comparisonFieldNames = search.map((item: any) => item.fieldName)

        // Filter options to find those that exist in the comparison array
        const matchedOptions = options.filter((option:any) =>
          comparisonFieldNames.includes(option.value)
        )
        setSelectedOptions(matchedOptions)

      // setSele
    } else{
      setSelectedOptions([])
    }

  }, [search])
  const getSearchValue = (columnId: string) => {
    // Find the matching value for the columnId
    const match = search?.find((item: any) => item?.fieldName === columnId)
    return match ? match?.value : "" // Default to an empty string if no match
  }

  const handleChange = (selectedOptions: any) => {
    setSelectedOptions(selectedOptions)
    // Extract the values of the selected options
    const selectedValues = selectedOptions?.map((item: any) => item.value) || []

    // Find the missing options
    const missingOptions = options.filter(
      (option: any) => !selectedValues.includes(option.value)
    )

    // Call onSearch for each missing option
    missingOptions.forEach((missingOption:any) => {
      onSearch(missingOption.value, "")
    })

    // Call onSearch for each selected option
    selectedOptions?.forEach((item: any) => {
      onSearch(item.value, true)
    })
  }

  return (
    <Fragment>
      <div className="table-responsive text-sm">
        <div className={divClass}>
          <div className="flex justify-start items-start gap-3 mt-2 mx-2 flex-col md:flex-row md:justify-between md:items-center">
            {resetFilter && (
              <Button
                onClick={() => {
                  onSort([])
                  onSearch("", "")
                }}
                label="Reset Filters"
                styleType="primary"
              />
            )}
            {options && (
              <Select
                isMulti
                value={selectedOptions}
                options={options}
                onChange={handleChange}
                components={{
                  Option: CustomOption,
                  MultiValue: CustomMultiValue,
                }}
                className="min-w-52 h-auto"
              />
            )}
          </div>
          <Table
            hover={clickable}
            className={"table table-striped table-nowrap align-middle mb-0"}
          >
            <thead className={theadClass}>
              {getHeaderGroups()?.map((headerGroup: any) => (
                <tr className={trClass} key={headerGroup.id}>
                  {headerGroup.headers?.map((header: any) => {
                    return (
                      <th key={header.id} className={thClass}>
                        {header.isPlaceholder ? null : (
                          <React.Fragment>
                            {/* Render header label */}
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {/* Conditionally render sorting icon */}
                            <button
                              onClick={() =>
                                handleSortClick(header.id, table, sort, onSort)
                              }
                            >
                              {header?.column?.columnDef?.enableSorting &&
                                (sort?.fieldName !== header.id ? (
                                  <img
                                    src={defaultArrow}
                                    className="w-2.5 ml-2"
                                    alt="default sort"
                                  />
                                ) : (
                                  sort?.fieldName === header.id &&
                                  (sort?.value === "asc" ? (
                                    <img
                                      src={upArrow}
                                      className="w-2.5 ml-2"
                                      alt="default sort"
                                    />
                                  ) : (
                                    <img
                                      src={downArrow}
                                      className="w-2.5 ml-2"
                                      alt="default sort"
                                    />
                                  ))
                                ))}
                            </button>
                            {header.column.columnDef.enableColumnFilter ? (
                              <Filter
                                column={header.column}
                                onSearch={onSearch}
                                filterValue={getSearchValue(header.id)}
                              />
                            ) : header.column.columnDef.enableDropDown ? (
                              <DropDownValue
                                values={header.column.columnDef?.dropDownValues}
                                onSearch={onSearch}
                                filterValue={getSearchValue(header.id)}
                              />
                            ) : (
                              header.column.columnDef.disableSort && (
                                <div className="h-4" />
                              )
                            )}
                          </React.Fragment>
                        )}
                      </th>
                    )
                  })}
                </tr>
              ))}
            </thead>

            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={10}>
                    <div className={`flex justify-center items-center h-12 md:h-28`}>
                      <div className="loader" />
                    </div>
                  </td>
                </tr>
              ) : data?.length !== 0 ? (
                getRowModel()?.rows?.map((row: any) => (
                  <tr
                    key={row.id}
                    className={`${clickable && "cursor-pointer"}`}
                  >
                    {row?.getVisibleCells()?.map((cell: any) => (
                      <td
                        key={cell.id}
                        {...(clickable && {
                          onClick: () => {
                            if (isHistoryNavigate) {
                              let additionalData = { key: data[row.id] }
                              if (navigateData) {
                                additionalData = {
                                  key: {
                                    id: data[row.id],
                                    navigateData,
                                  },
                                }
                              }
                              history(navigateTo, { state: additionalData })
                            }
                          },
                        })}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td className={`text-center text-gray-600 h-16 md:h-32` }
                  colSpan={10}>
                    You are all caught up!
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>

      {!currentPageNumber &&
        showPagination &&
        data?.length > 5 &&
        BasicPagination(
          data,
          getCanPreviousPage,
          previousPage,
          getPageOptions,
          getState,
          setPageIndex,
          getCanNextPage,
          nextPage
        )}

      {currentPageNumber &&
        showPagination &&
        PaginatedIndex(currentPageNumber, pageLimit, setCurrentPageNumber)}
    </Fragment>
  )
}

export default TableContainer
