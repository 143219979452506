import Button from "Components/Common/Button"
import { TicketListing } from "pages/Tickets/Listing"
import {
  GetTaskStatistics,
  GetWorkflowStatistics,
} from "pages/Workflow/PaymentProcessing/Statistics"
import { LastFilesTable } from "pages/Workflow/PaymentProcessing/Statistics/last_files"
import { Card, CardBody, CardHeader } from "reactstrap"
import {
  CHECK_ACH,
  CHECK_DEBIT_CARD,
  CHECK_MONEY_GRAM,
  CHECK_WAGE_DEDUCTION,
  OUTBOUND_TASKS,
  RESTART_ACH,
  RESTART_DEBIT_CARD,
  RESTART_MONEY_GRAM,
  RESTART_WAGE_DEDUCTION,
  STATUS_COMPLETED,
  TASK_APPROVE_CREATE_BUNDLE,
  TASK_CHECK_WAGEDEDUCTION,
  TASK_CREATE_BUNDLE,
  TASK_UPLOAD_BUNDLE,
} from "Components/constants/constants"

export const renderTaskStatistics = (
  stats: any,
  isOutbound: any,
  isCreateBundle: any
) => {
  return (
    <Card className="border-1 mt-4">
      <CardHeader>
        <h5 className="card-title mb-0">Statistics</h5>
      </CardHeader>
      <CardBody className="text-xs">
        <GetWorkflowStatistics
          statistics={stats}
          isOutbound={isOutbound}
          isCreateBundle={isCreateBundle}
        />
      </CardBody>
    </Card>
  )
}

export const renderFailureTaskStatistics = (
  taskStatistics: any,
  taskName: any
) => {
  const task = taskStatistics?.find((task: any) => task?.keyName === taskName)
  const stats = Array.isArray(task?.result)
    ? task?.result
    : task?.result
    ? [task?.result]
    : []

  // LastFilesTable
  return (
    <Card className="border-1 mt-2">
      <CardHeader>
        <h5 className="card-title mb-0">Statistics</h5>
      </CardHeader>
      <CardBody className="text-xs -mt-6">
        <GetTaskStatistics statistics={stats} taskName={taskName} />
        {taskName === TASK_CHECK_WAGEDEDUCTION && (
          <LastFilesTable files={task?.result[0]?.last_10_files} />
        )}
      </CardBody>
    </Card>
  )
}

export const renderFailureTaskTickets = (taskName: any, ticketsData: any) => {
  let data

  const taskNameToKeyNameMap: any = {
    [RESTART_MONEY_GRAM]: CHECK_MONEY_GRAM,
    [RESTART_DEBIT_CARD]: CHECK_DEBIT_CARD,
    [RESTART_WAGE_DEDUCTION]: CHECK_WAGE_DEDUCTION,
    [RESTART_ACH]: CHECK_ACH,
  }

  const keyName = taskNameToKeyNameMap[taskName]
  if (keyName) {
    const task = ticketsData.find((task: any) => task?.taskName === keyName)
    data = task ? [task] : []
  }

  return (
    <Card className="border-1 mt-4">
      <CardHeader>
        <h5 className="card-title mb-0">Tickets</h5>
      </CardHeader>
      <CardBody className="text-xs">
        <TicketListing tickets={data} />
      </CardBody>
    </Card>
  )
}

export const TaskActionContent = ({
  openTab,
  workflowId,
  handleEscalate,
  setOpenTab,
  handleConfirm,
  tasks,
  taskStatistics,
  isOutbound,
  ticketsData,
}: any) => {
  return (
    <div className="px-2 flex flex-col h-5/6 justify-between overflow-y-auto">
      <div className="flex-col gap-4">
        <div className="flex-col gap-2 px-3">
          <div className="flex w-3/4 pb-2">
            <span className="font-semibold w-1/2">Task Name: </span>
            <span>{openTab?.actionName}</span>
          </div>
          <div className="flex w-3/4 pb-2">
            <span className="font-semibold w-1/2">Task ID: </span>
            <span>{openTab?.taskId}</span>
          </div>
          <div className="flex w-3/4 pb-2">
            <span className="font-semibold w-1/2">Workflow ID: </span>
            <span>{workflowId}</span>
          </div>
        </div>
        {OUTBOUND_TASKS.includes(openTab?.actionKeyName) ? (
          <>
            {renderFailureTaskStatistics(
              taskStatistics,
              openTab?.actionKeyName
            )}
            {renderFailureTaskTickets(openTab?.actionName, ticketsData)}
          </>
        ) : tasks?.some(
            (task: any) =>
              task.key_name === TASK_CREATE_BUNDLE &&
              task.status === STATUS_COMPLETED
          ) ? (
          openTab?.actionKeyName === TASK_UPLOAD_BUNDLE ? (
            isOutbound &&
            (() => {
              const taskCreateBundleResult = tasks.find(
                (task: any) => task?.key_name === TASK_CREATE_BUNDLE
              )?.result

              const creditAmount = taskCreateBundleResult?.credit_amount ?? "-"
              const transactionCount =
                taskCreateBundleResult?.transaction_count ?? "-"

              return (
                <div className="flex-col gap-2 px-3">
                  <div className="flex w-3/4 pb-2">
                    <span className="font-semibold w-1/2">Credit Amount: </span>
                    <span>{creditAmount}</span>
                  </div>
                  <div className="flex w-3/4 pb-2">
                    <span className="font-semibold w-1/2">
                      Transaction Count:{" "}
                    </span>
                    <span>{transactionCount}</span>
                  </div>
                </div>
              )
            })()
          ) : (
            openTab?.actionKeyName === TASK_APPROVE_CREATE_BUNDLE &&
            renderTaskStatistics(
              [
                tasks.find((task: any) => task?.key_name === TASK_CREATE_BUNDLE)
                  ?.result,
              ],
              isOutbound,
              true
            )
          )
        ) : null}
      </div>
      <div className="flex justify-end gap-2 mr-4">
        {openTab.actionKeyName === "approve_create_bundle" ||
        openTab.actionKeyName === "upload_bundle" ? (
          <Button
            onClick={handleEscalate}
            label="Escalate"
            styleType="primary"
          />
        ) : (
          <Button
            onClick={() => setOpenTab({ open: false })}
            label="Cancel"
            styleType="secondary"
          />
        )}
        <Button
          onClick={handleConfirm}
          label={openTab?.label}
          styleType="confirm"
        />
      </div>
    </div>
  )
}
