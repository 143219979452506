import { createSlice } from "@reduxjs/toolkit"

// Initial state
const initialState = {
  tickets: [], // Single global state to hold all tickets
  loadingTickets: false,
  error: null,
}

const ticketSlice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    fetchTicketsStart: (state) => {
      state.loadingTickets = true // Set loading to true when the fetch starts
    },
    // Action to update workflows from SSE data
    updateTickets: (state, action) => {
      try {
        const updatedTickets = action.payload // This should be an array of updated ticket objects

        // Create a map of existing tickets by ID for quick lookup
        const existingTicketsMap = new Map(
          state.tickets.map((ticket) => [ticket.ticket_id, ticket])
        )

        // Iterate over the updated tickets
        updatedTickets.forEach((updatedTicket) => {
          if (existingTicketsMap.has(updatedTicket.ticket_id)) {
            // Update the existing ticket
            existingTicketsMap.set(updatedTicket.ticket_id, {
              ...existingTicketsMap.get(updatedTicket.ticket_id), // Merge existing ticket properties
              ...updatedTicket, // Merge updated properties
            })
          } else {
            // Add new ticket to the map
            existingTicketsMap.set(updatedTicket.ticket_id, updatedTicket)
          }
        })

        // Convert the map back to an array, sort by ticket_id in descending order, and update the state
        state.tickets = Array.from(existingTicketsMap.values()).sort(
          (a, b) => b.ticket_id - a.ticket_id
        )
      } catch (e) {
        console.error("Error updating tickets:", e)
        // Optionally, you can set an error state here if needed
      }
    },
    fetchTicketsSuccess: (state, action) => {
      state.tickets = action.payload // Store all tickets in the global state
      state.loadingTickets = false
    },
    fetchTicketsFailure: (state, action) => {
      state.loadingTickets = false
      state.error = action?.payload
    },
  },
})

// Export actions and reducer
export const {
  updateTickets,
  fetchTicketsSuccess,
  fetchTicketsFailure,
  fetchTicketsStart,
} = ticketSlice.actions
export default ticketSlice.reducer
