import { createSlice } from "@reduxjs/toolkit"

// Initial state
const initialState = {
  holidays: [], // Single global state to hold all holidays
  error: null,
}

const holidaysSlice = createSlice({
  name: "holidays",
  initialState,

  reducers: {
    fetchHolidaysSuccess: (state, action) => {
      state.holidays = action.payload // Store all holidays in the global state
    },
    fetchHolidaysFailure: (state, action) => {
      state.holidays = action.payload
    },
  },
})

// Export actions and reducer
export const {
  fetchHolidaysSuccess,
  fetchHolidaysFailure,
} = holidaysSlice.actions

export default holidaysSlice.reducer
