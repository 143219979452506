import { createSlice } from "@reduxjs/toolkit"

// Initial state
const initialState = {
  permissions: {}, 
  error: null,
}

const permissionSlice = createSlice({
  name: "permissions",
  initialState,

  reducers: {
    fetchPermissionsSuccess: (state, action) => {
      state.permissions = action.payload 
    },
    fetchPermissionsFailure: (state, action) => {
      state.permissions = action.payload
    },
  },
})

// Export actions and reducer
export const {
  fetchPermissionsSuccess,
  fetchPermissionsFailure,
} = permissionSlice.actions

export default permissionSlice.reducer
