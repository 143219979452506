import React, { useState, useEffect, useRef } from "react"
import { Card, Col, Container, Row } from "reactstrap"
import BreadCrumb from "../../../Components/Common/BreadCrumb"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { calculateCustomBusinessDaysBefore } from "../Helpers/helper"
import { determineBaseUrlAndPerformApiCall } from "slices/interceptor/interceptor"
import {
  API_SUCCESS_RESPONSE,
  MAX_DATE,
  US_DOLLAR_SYMBOL,
  ZERO_OUT_SUMMARY_REPORT,
} from "Components/constants/constants"
import {
  formatZOSummartReportForDisplay,
  FormatCurrency,
} from "helpers/Formatters/Format"
import { ZOSummaryListing } from "./Listing"
import { toast, ToastContainer } from "react-toastify"
import { formatDate } from "helpers/Formatters/DateFormatter"
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { addDates } from "slices/Reports/InboundSummary/reducer"
import { DateTime } from "ts-luxon"
import { fetchHolidays } from "slices/Holidays/thunk"

const ZeroOutSummaryReport = () => {
  document.title = "Zero Out Summary | TFS Processing App"

  const location = useLocation()
  const [zOSummary, setZOSummary] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [disabledDates, setDisabledDates] = useState<any>(null)
  const datePickerRef = useRef<any>(null)
  const [maxDate, setMaxDate] = useState<any>(null)
  const [details, setDetails] = useState<any>({})
  const dispatch = useDispatch<any>()

  const { holidays } = useSelector((state: any) => state.Holidays)

  // Helper function to check if a date is a weekend
  const isWeekend = (date: Date): boolean => {
    const dayOfWeek = date.getDay() // 0 = Sunday, 6 = Saturday
    return dayOfWeek === 0 || dayOfWeek === 6
  }

  // Helper function to check if a date is a holiday
  const isHoliday = (date: Date, holidays: Date[]): boolean => {
    if (!holidays || holidays.length === 0) return false

    // Convert the input date to ET and format it as "YYYY-MM-DD"
    const dateInET = DateTime.fromJSDate(date).toISODate()

    // Check if the date exists in the holidays array
    return holidays.some((holiday) => {
      const holidayInET = DateTime.fromJSDate(holiday).toISODate()
      return holidayInET === dateInET
    })
  }

  useEffect(() => {
    if (holidays) {
      const dates = holidays?.map((item: any) =>
        DateTime.fromISO(item).toJSDate()
      )
      setDisabledDates(dates)
    }
  }, [holidays])

  useEffect(() => {
    dispatch(fetchHolidays())

    if (location?.state?.key?.outboundDate) {
      const parsedDate = location?.state?.key?.outboundDate
      setSelectedDate(parsedDate)
    } else if (holidays) {
      const dates = holidays?.map((item: any) =>
        DateTime.fromISO(item).toJSDate()
      )
      setDisabledDates(dates)

      const result = calculateCustomBusinessDaysBefore(MAX_DATE, dates, 2)
      setSelectedDate(result.startOf("day").toJSDate())
    }
  }, [])

  const handlePreviousDate = () => {
    setSelectedDate((prevDate) => {
      const date = prevDate ? new Date(prevDate) : new Date()

      // Convert the date to America/New_York time zone
      const dateInNY = DateTime.fromJSDate(date)
        .setZone("America/New_York")
        .startOf("day")
        .toJSDate()

      // Move back one day
      dateInNY.setDate(dateInNY.getDate() - 1)

      // Skip weekends and holidays
      while (isWeekend(dateInNY) || isHoliday(dateInNY, disabledDates)) {
        dateInNY.setDate(dateInNY.getDate() - 1)
      }

      return dateInNY
    })
  }

  const handleNextDate = () => {
    setSelectedDate((prevDate) => {
      const currentDate = prevDate ? new Date(prevDate) : new Date()

      // Skip weekends and holidays

      currentDate.setDate(currentDate.getDate() + 1)

      while (isWeekend(currentDate) || isHoliday(currentDate, disabledDates)) {
        currentDate.setDate(currentDate.getDate() + 1)
      }

      // Ensure the date does not exceed the max date
      const result = calculateCustomBusinessDaysBefore(
        MAX_DATE,
        disabledDates,
        2
      )
      const maxDate = result.startOf("day")

      if (currentDate > maxDate.toJSDate()) {
        return maxDate.toJSDate()
      }

      return currentDate
    })
  }

  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true)
    }
  }

  useEffect(() => {
    if (disabledDates) {
      const result = calculateCustomBusinessDaysBefore(
        MAX_DATE,
        disabledDates,
        2
      )
      setMaxDate(result)
    }
  }, [disabledDates])

  useEffect(() => {
    if (selectedDate) {
      setLoading(true)
      const year = selectedDate.getFullYear()
      const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0")
      const day = selectedDate.getDate().toString().padStart(2, "0")
      const formattedSelectedDate = `${year}-${month}-${day}`

      const getSummary = async () => {
        try {
          await determineBaseUrlAndPerformApiCall(
            `${ZERO_OUT_SUMMARY_REPORT}${formattedSelectedDate}`,
            "GET"
          )
            .then((response: any) => {
              if (response?.message !== API_SUCCESS_RESPONSE) {
                setDetails({})
                setZOSummary([])
                setLoading(false)
              } else {
                setDetails({
                  fboStartingBalance: response?.data?.fbo_starting_balance,
                  esquireFeeCollected: response?.data?.esquire_fee_collected,
                  zeroOutBalance: response?.data?.zero_out_balance,
                  unsettledLateReturns: response?.data?.unsettled_late_returns,
                  activeWindow: response?.data?.active_window,
                })

                const item = response?.data?.transactions?.map(
                  (item: any, index: any) => {
                    const formmatedReportData = item?.data?.map((data: any) => {
                      return formatZOSummartReportForDisplay(data)
                    })

                    const report = {
                      inboundDate: formatDate(item?.inbound_date),
                      index: index + 1,
                      data: formmatedReportData,
                    }
                    return report
                  }
                )

                const inboundDates = response?.data?.transactions?.map(
                  (item: any) => item?.inbound_date
                )
                dispatch(addDates(inboundDates))

                setZOSummary(item)
                setLoading(false)
              }
            })
            .catch((errorResp) => {
              setDetails({})
              setZOSummary([])
              setLoading(false)
              toast.error(errorResp, { position: "top-center" })
              console.error(errorResp)
            })
        } catch (error: any) {
          setDetails({})
          setZOSummary([])
          setLoading(false)
          toast.error(error?.message, { position: "top-center" })
          console.error(error)
        }
      }

      getSummary()
    }
  }, [selectedDate])

  return (
    <React.Fragment>
      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="page-content" id="processingContent">
          <ToastContainer />
          <Container fluid>
            <BreadCrumb
              title="Zero Out Summary Report"
              pageTitle="Report"
              action="/zero-out-summary-report"
            />

            <div className="flex flex-col items-center justify-center gap-2">
              <p className="text-gray-700 font-semibold m-0 p-0 text-sm">
                As of End of Day
              </p>
              <div className="flex items-center gap-x-4">
                <div
                  onClick={handlePreviousDate}
                  className="rounded bg-[#31506A] cursor-pointer hover:bg-[#2b435a] px-2"
                >
                  <i className="ri-arrow-left-line text-white text-lg" />
                </div>
                <div className="relative inline-flex items-center w-auto justify-centre">
                  <DatePicker
                    className="border-1 border-black-500 rounded-md font-bold placeholder:text-black h-8 pl-4 text-sm w-36"
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="MM-dd-yyyy"
                    placeholderText={maxDate.toFormat("MM-dd-yyyy")}
                    ref={datePickerRef}
                    excludeDates={disabledDates}
                    maxDate={calculateCustomBusinessDaysBefore(
                      MAX_DATE,
                      disabledDates,
                      2
                    )
                      .startOf("day")
                      .toJSDate()}
                    filterDate={(date) =>
                      !isWeekend(date) && !isHoliday(date, disabledDates)
                    } // Disable weekends and holidays
                  />
                  <i
                    className="ri-calendar-2-line absolute right-5 bottom-1 text-gray-500 text-lg cursor-pointer"
                    onClick={handleIconClick}
                  />
                </div>
                <div
                  onClick={handleNextDate}
                  className="rounded bg-[#31506A] cursor-pointer hover:bg-[#2b435a] px-2"
                >
                  <i className="ri-arrow-right-line text-white text-lg" />
                </div>
              </div>
            </div>

            <Row>
              <Col>
                <Card className="mt-4">
                  <ZOSummaryListing
                    selectedDate={selectedDate}
                    summaryData={zOSummary}
                    fboStartingBalance={details?.fboStartingBalance}
                    activeWindows={details?.activeWindow}
                  />
                </Card>
              </Col>
            </Row>

            <div className="flex flex-col mb-4 gap-2">
              <div className="flex justify-end w-full">
                <div className="bg-white rounded flex gap-2 justify-end pl-4 pr-2 py-2 border border-gray-300">
                  <p className="font-bold m-0 p-0 text-sm">
                    Unsettled Late Returns:
                  </p>
                  <p className="text-blue-500 font-bold m-0 p-0 text-sm">
                    {US_DOLLAR_SYMBOL}
                    {details?.unsettledLateReturns
                      ? FormatCurrency(details?.unsettledLateReturns)
                      : ""}
                  </p>
                </div>
              </div>
              <div className="flex justify-end w-full">
                <div className="bg-white rounded flex gap-2 justify-end pl-4 pr-2 py-2 border border-gray-300">
                  <p className="font-bold m-0 p-0 text-sm">
                    {" "}
                    Zero Out Balance:
                  </p>
                  <p className="text-blue-500 font-bold m-0 p-0 text-sm">
                    {US_DOLLAR_SYMBOL}
                    {details?.zeroOutBalance
                      ? FormatCurrency(details?.zeroOutBalance)
                      : ""}
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default ZeroOutSummaryReport
