import React from "react"

export const Slider = ({ isOpen, setOpen, actionName, children }: any) => {
  return (
    <div
      className={`slider-content w-11/12 sm:w-3/6 md:5/12 lg:w-2/6 h-full transition-all duration-700 ${
        isOpen ? "translate-x-0 opacity-100" : " opacity-0 translate-x-full"
      }`}
    >
      <div className="h-[75%] pl-3">
        <div className="bg-white border-2 border-[#B6B6B6] py-2 h-full rounded-lg  mb-10">
          <div className="flex gap-3 justify-between items-center px-4 py-2">
            <p className="uppercase font-medium text-sm m-0">{actionName}</p>
            <div
              onClick={() => setOpen({ open: false })}
              className="cursor-pointer"
            >
              <i className="ri-close-line font-extralight bg-red-100 p-2 rounded-md text-red-500"></i>
            </div>
          </div>
          <hr className="mt-2 text-gray-500" />
          {children}
        </div>
      </div>
    </div>
  )
}
