import { Navigate } from "react-router-dom"

// Import your components
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Workflow from "../pages/Workflow"
import PaymentProcessing from "pages/Workflow/PaymentProcessing"
import Action from "pages/Actions"
import Ticket from "pages/Tickets"
import ZeroOutSummaryReport from "pages/Reports/ZeroOutSummary/index"
import InboundDaySummary from "pages/Reports/InboundDaySummary"
import TimelineReport from "pages/Reports/Timeline"
import ZeroOut from "pages/Reports/FeeCalculation"
import { useSelector } from "react-redux"

const Routes = () => {
  const { permissions } = useSelector((state: any) => state.Permissions)

  const workflowPermission = permissions?.resource_name?.find(
    (resource: any) => resource.resource_name === "workflow"
  )

  const ticketsPermission = permissions?.resource_name?.find(
    (resource: any) => resource.resource_name === "tickets"
  )

  const actionPermission = permissions?.resource_name?.find(
    (resource: any) => resource.resource_name === "action"
  )

  const zeroOutPermission = permissions?.resource_name?.find(
    (resource: any) => resource.resource_name === "reports"
  )


  const authProtectedRoutes = [
    // Default Path
    { path: "/", exact: true, component: <Navigate to="/workflow" /> },
    // Workflow Page
    workflowPermission && { path: "/workflow", component: <Workflow /> },
    workflowPermission && {
      path: "/payment-processing",
      component: <PaymentProcessing data={{}} />,
    },
    // Action Page
    actionPermission && { path: "/action", component: <Action /> },
    // Tickets Page
    ticketsPermission && { path: "/tickets", component: <Ticket /> },
    // Report Page
    zeroOutPermission && {
      path: "/zero-out-summary-report",
      component: <ZeroOutSummaryReport />,
    },
    zeroOutPermission && {
      path: "/fees_calculation",
      component: <ZeroOut />,
    },
    zeroOutPermission && {
      path: "/inbound-day-summary",
      component: <InboundDaySummary />,
    },
    zeroOutPermission && {
      path: "/timeline",
      component: <TimelineReport />,
    },
  ].filter(Boolean) // Filter out any `false` values

  const publicRoutes = [
    // Authentication Page
    { path: "/logout", component: <Logout /> },
    { path: "/login", component: <Login /> },
  ]

  return { authProtectedRoutes, publicRoutes }
}

export default Routes
