import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor"
import { OUTBOUND_ACH_WORKFLOW,STATS_CREATE_BUNDLE } from "Components/constants/constants"
import {
  fetchstatisticsByWorkflowIdFailure,
  fetchstatisticsByWorkflowIdStart,
  fetchstatisticsByWorkflowIdSuccess,
} from "./reducer"

export const getStatisticsByWorkflowId =
  (workflowId, workflow) => (dispatch) => {
    dispatch(fetchstatisticsByWorkflowIdStart())

    try {
      // Statistics Only for Outbound
      if (workflow?.workflow_key_name === OUTBOUND_ACH_WORKFLOW) {
        determineBaseUrlAndPerformApiCall(
          `${STATS_CREATE_BUNDLE}${workflowId}`,
          "GET"
        )
          .then((response) => {
            if (response?.length > 0) {
              dispatch(fetchstatisticsByWorkflowIdSuccess(response))
            } else {
              dispatch(fetchstatisticsByWorkflowIdSuccess([]))
            }
          })
          .catch((errorResp) => {
            dispatch(fetchstatisticsByWorkflowIdFailure(errorResp.message))
          })
      }
    } catch (error) {
      dispatch(
        fetchstatisticsByWorkflowIdFailure(error.message || "An error occurred")
      )
    }
  }
