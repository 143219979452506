import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const Navdata = () => {
  const history = useNavigate()
  //state data
  const [isWorkflow, setIsWorkflow] = useState<boolean>(false)
  const [isActions, setIsActions] = useState<boolean>(false)
  const [isTickets, setIsTickets] = useState<boolean>(false)
  const [isReports, setIsReports] = useState<boolean>(false)
  const [iscurrentState, setIscurrentState] = useState("")
  const { permissions } = useSelector((state: any) => state.Permissions)

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu")
      const iconItems: any = ul?.querySelectorAll(".nav-icon.active")
      const activeIconItems = [...iconItems]
      activeIconItems.forEach((item) => {
        item.classList.remove("active")
        const id = item.getAttribute("subitems")
        if (document.getElementById(id)) {
          document?.getElementById(id)?.classList?.remove("show")
        }
      })
    }
  }

  const workflowPermission = permissions?.resource_name?.find(
    (resource: any) => resource.resource_name === "workflow"
  )

  const ticketsPermission = permissions?.resource_name?.find(
    (resource: any) => resource.resource_name === "tickets"
  )

  const actionPermission = permissions?.resource_name?.find(
    (resource: any) => resource.resource_name === "action"
  )

  const zeroOutPermission = permissions?.resource_name?.find(
    (resource: any) => resource.resource_name === "reports"
  )

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel")
    if (iscurrentState !== "Workflows") {
      setIsWorkflow(false)
    }
    if (iscurrentState !== "Actions") {
      setIsActions(false)
    }
    if (iscurrentState !== "Tickets") {
      setIsTickets(false)
    }
    if (iscurrentState !== "Reports") {
      setIsReports(false)
    }
  }, [history, iscurrentState, isWorkflow, isReports])

  const menuItems: any = [
    {
      label: "Menu",
      isHeader: true,
    },
    workflowPermission && {
      id: "workflow",
      label: "Workflows",
      icon: "rmx ri-git-pull-request-line",
      link: "/workflow",
      stateVariables: isWorkflow,
      click: function() {
        setIsWorkflow(!isWorkflow)
        setIscurrentState("Workflows")
      },
    },
    actionPermission && {
      id: "action",
      label: "Actions",
      icon: "rmx ri-task-line",
      link: "/action",
      stateVariables: isActions,
      click: function() {
        setIsActions(!isActions)
        setIscurrentState("Actions")
      },
    },
    ticketsPermission && {
      id: "tickets",
      label: "Tickets",
      icon: "rmx ri-ticket-line",
      link: "/tickets",
      stateVariables: isTickets,
      click: function() {
        setIsTickets(!isTickets)
        setIscurrentState("Tickets")
      },
    },
    zeroOutPermission && {
      id: "reports",
      label: "Reports",
      icon: "rmx ri-file-chart-line",
      link: "/#",
      click: function(e: any) {
        e.preventDefault()
        updateIconSidebar(e)
        setIsReports(!isReports)
        setIscurrentState("Reports")
      },
      stateVariables: isReports,
      subItems: [
        {
          id: "zeroOutSummaryReport",
          label: "Zero Out Summary Report",
          link: "/zero-out-summary-report",
          parentId: "reports",
        },
        {
          id: "feesCalculation",
          label: "Fees Calculation",
          link: "/fees_calculation",
          parentId: "reports",
        },
      ].filter(Boolean),
    },
  ].filter(Boolean)
  return <React.Fragment>{menuItems}</React.Fragment>
}
export default Navdata
