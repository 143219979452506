import { ZERO_OUT_HOLIDAYS, API_SUCCESS_RESPONSE } from "Components/constants/constants"
import { determineBaseUrlAndPerformApiCall } from "slices/interceptor/interceptor"
import { fetchHolidaysSuccess, fetchHolidaysFailure } from './reducer'

export const fetchHolidays = () => async (dispatch) => {

  try {
    const response = await determineBaseUrlAndPerformApiCall(
      ZERO_OUT_HOLIDAYS,
      "GET"
    )
    if (response?.message === API_SUCCESS_RESPONSE) {
      dispatch(fetchHolidaysSuccess(response?.data))
    } else {
      dispatch(fetchHolidaysFailure([]))
    }
  } catch (e) {
    console.error(e)
    dispatch(fetchHolidaysFailure([]))
}
}
