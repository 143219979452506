import React, { Fragment, useEffect } from "react"
import { Table } from "reactstrap"

import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table"

import { US_DOLLAR_SYMBOL } from "Components/constants/constants"
import { useNavigate } from "react-router-dom"
import { FormatCurrency } from "helpers/Formatters/Format"
import NoDataFound from "../../../../assets/images/no-data.avif"

interface TableContainerProps {
  columns?: any
  data?: any
  customPageSize?: any
  divClass?: any
  clickable?: boolean
  navigateTo?: any
  fboStartingBalance: any
  selectedDate?: any
  activeWindows?: any
}

const TableContainer = ({
  columns,
  data,
  customPageSize,
  divClass,
  clickable,
  navigateTo,
  fboStartingBalance,
  selectedDate,
  activeWindows,
}: TableContainerProps) => {
  const history = useNavigate()

  const table = useReactTable({
    columns,
    data,   
    getCoreRowModel: getCoreRowModel(),
  })

  const { getHeaderGroups, setPageSize } = table

  useEffect(() => {
    if (Number(customPageSize)) {
      setPageSize(Number(customPageSize))
    }
  }, [customPageSize, setPageSize])

  return (
    <Fragment>
      <div className="table-responsive">
        <div className={divClass}>
          <Table className={"table-nowrap align-middle mb-0"}>
            <thead>
              <tr>
                <th colSpan={5}>
                  <div className="flex justify-between flex-row items-center">
                    <p className="p-0 m-0 text-sm font-bold uppercase text-gray-700">
                      Active Window (DAYS) - {activeWindows}
                    </p>
                    <div className="flex gap-2">
                      <p className="text-gray-700 p-0 m-0 text-sm font-bold">
                        FBO Starting Balance :
                      </p>
                      <p className="text-blue-600 font-bold m-0 p-0 text-sm">
                        {US_DOLLAR_SYMBOL} {FormatCurrency(fboStartingBalance)}
                      </p>
                    </div>
                  </div>
                </th>
              </tr>
              {getHeaderGroups().map((headerGroup: any) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header: any, index: number) => (
                    <th
                      key={header.id}
                      className={`w-1/5 min-w-56 text-center text-sm font-bold p-0 m-0 py-2 ${
                        index !== headerGroup.headers.length - 1
                          ? "border-r-[1px]"
                          : ""
                      }`}
                      {...(clickable === true && {
                        onClick: header.column.getToggleSortingHandler(),
                      })}
                    >
                      {header.isPlaceholder ? null : (
                        <React.Fragment>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </React.Fragment>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {data.length === 0 ? (
                <tr>
                  <td colSpan={5} className="m-0 p-0">
                    <div className="flex items-center justify-center bg-white">
                      <img
                        src={NoDataFound}
                        alt="No Records Found"
                        className="w-72 h-72"
                      />
                    </div>
                  </td>
                </tr>
              ) : (
                <tr className="bg-[#f2f2f7]">
                  <td colSpan={5} className="p-0 m-0">
                    <div className="flex flex-col gap-3 w-full max-h-[26rem] overflow-y-auto">
                      {data?.map((item: any, index: number) => (
                        <div
                          key={index}
                          className={`cursor-pointer w-full grid grid-cols-5 bg-white border-1
                           border-gray-300 rounded-md
                           hover:border-blue-400 hover:border-double hover:border-4`}
                          onClick={() => {
                            const key = {
                              inboundDate: item?.inboundDate,
                              asOfEndOfDay: selectedDate,
                            }
                            history(navigateTo, { state: key })
                          }}
                        >
                          <div className="row-span-4">
                            <div className="flex justify-center items-center w-full h-full  border-r-[1px]">
                              <div className="flex flex-col text-gray-700 w-full text-left ml-8">
                                <h2 className="m-0 p-0">{item?.index}</h2>
                                <h5 className="font-bold text-xs md:text-sm">
                                  {item?.inboundDate}
                                </h5>
                              </div>
                            </div>
                          </div>
                          <div className="col-span-4">
                            {item?.data?.map(
                              (result: any, resultIndex: number) => (
                                <div
                                  key={resultIndex}
                                  className={`grid grid-cols-4 w-full rounded-md ${
                                    resultIndex % 2 === 0 ? "bg-[#F4F3F2]" : ""
                                  }`}
                                >
                                  <div className="pl-2 pt-1 min-w-48 border-r-[1px]">
                                    <p className="p-0 m-0 pb-2 text-sm">
                                      {result?.productType || "-"}
                                    </p>
                                  </div>
                                  <div className="text-right min-w-48 pr-2 border-r-[1px]">
                                    <p
                                      className={`mt-1 d-flex justify-content-between p-0 m-0 text-sm ${
                                        result?.trusteePortionDelayed
                                          ? "text-red-600"
                                          : "text-secondary"
                                      }`}
                                    >
                                      <span className="pl-1">
                                        {US_DOLLAR_SYMBOL}
                                      </span>
                                      <span>
                                        {result?.trusteePortion
                                          ? FormatCurrency(
                                              result?.trusteePortion
                                            )
                                          : "-"}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="text-right min-w-48 pr-2 border-r-[1px]">
                                    <p
                                      className={`mt-1 d-flex justify-content-between p-0 m-0 text-sm ${
                                        result?.feePortionDelayed
                                          ? "text-red-600"
                                          : "text-secondary"
                                      }`}
                                    >
                                      <span className="pl-1">
                                        {US_DOLLAR_SYMBOL}
                                      </span>
                                      <span>
                                        {result?.feePortion
                                          ? FormatCurrency(result?.feePortion)
                                          : "-"}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="text-secondary text-right pr-2 min-w-48">
                                    <p className="mt-1 d-flex justify-content-between p-0 m-0 text-sm">
                                      <span className="pl-1">
                                        {US_DOLLAR_SYMBOL}
                                      </span>
                                      <span>
                                        {result?.runningZeroOutBal
                                          ? FormatCurrency(
                                              result?.runningZeroOutBal
                                            )
                                          : "-"}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </Fragment>
  )
}

export default TableContainer
