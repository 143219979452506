export const buttonConfig = {
    "start_outbound:ready": {
      label: "Start",
      actionKeyName: "start_outbound",
      actionName: "Start Outbound",
      condition:true
    },
    "create_bundle:ready": {
      label: "Start",
      actionKeyName: "create_bundle",
      actionName: "Start Create Bundle",
      condition:true
    },
    "approve_create_bundle:ready": {
      label: "Approve",
      actionKeyName: "approve_create_bundle",
      actionName: "Approve Create Bundle",
      condition:true
    },
    "upload_payment_bundle:ready": {
      label: "Start",
      actionKeyName: "upload_payment_bundle",
      actionName: "Start Upload Payment Bundle",
      condition:true
    },
    "upload_bundle:ready": {
      label: "Approve",
      actionKeyName: "upload_bundle",
      actionName: "Approve Upload Bundle",
      condition:true
    },
    "create_bundle:failed": {
      label: "Restart",
      actionKeyName: "create_bundle",
      actionName: "Restart Create Bundle",
      condition:true
    },
    "upload_payment_bundle:failed": {
      label: "Restart",
      actionKeyName: "upload_bundle",
      actionName: "Restart Upload Payment Bundle",
      condition:true
    },
    "check_ach:failed": {
      label: "Restart",
      actionKeyName: "check_ach",
      actionName: "Restart Check ACH",
      condition:true
    },
    "check_money_gram:failed": {
      label: "Restart",
      actionKeyName: "check_money_gram",
      actionName: "Restart Check Money Gram",
      condition:true
    },
    "check_debit_card:failed": {
      label: "Restart",
      actionKeyName: "check_debit_card",
      actionName: "Restart Check Debit Card",
      condition:true
    },
    "check_wage_deduction:failed": {
      label: "Restart",
      actionKeyName: "check_wage_deduction",
      actionName: "Restart Check Wage Deduction",
      condition:true
    },
  }
  
export const taskIconConfig = {
    start_outbound: {
      icon: {
        icon: "ri-folder-line"
      },
    },
    check_ach: {
      icon: {
        icon: "ri-check-line",
      },
    },
    check_money_gram: {
      icon: {
        icon: "ri-check-line",
      },
    },
    check_wage_deduction: {
      icon: {
        icon: "ri-check-line",
      },
    },
    check_debit_card: {
      icon: {
        icon: "ri-check-line",
      },
    },
    create_bundle: {
      icon: {
        icon: "ri-folder-line",
      },
    },
    approve_create_bundle: {
      icon: {
        icon: "ri-check-line",
      },
    },
    upload_payment_bundle: {
      icon: {
        icon: "ri-folder-upload-line",
      },
    },
    upload_bundle: {
      icon: {
        icon: "ri-check-line",
      },
    },
  }